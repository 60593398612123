<template>
  <div>
    <h3>Staff</h3>

    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Department</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr>
          <th><text-input :noWrap="true" v-model="nameFilter"/></th>
          <th>
            <select-input
                label="Department"
                :selectOptions="formDepartmentOptions"
                v-model="departmentFilter"
                :noWrap="true"
            ></select-input>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dbRow in dbRowsFiltered" :key="dbRow.id">
          <td class="align-middle">{{ dbRow.name }}</td>
          <td class="align-middle">{{ departmentTexts[dbRow.department] }}</td>
          <td>
            <button type="button" class="btn btn-block btn-outline-warning" @click="formFillFromRow(dbRow)">Edit</button>

          </td>
        </tr>
      </tbody>
    </table>

    <div class="card mb-3">
      <div class="card-body">
        <text-input label="Id" v-model="form.id" disabled v-show="form.id !== null"></text-input>
        <text-input label="Name" v-model="form.name" :error="formErrors.name"></text-input>
        <select-input
            label="Department"
            :selectOptions="formDepartmentOptions"
            v-model="form.department"
            :error="formErrors.department"
        ></select-input>

        <div class="form-group row mb-0">
          <div class="offset-sm-3 col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formSubmit" :disabled="anyErrors">{{ form.id === null ? 'Create' : 'Update' }}</button>
          </div>
          <div class="col-sm-4">
            <button class="btn btn-block btn-outline-warning" @click="formClear">Clear</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import Swal from "sweetalert2";

  import { API, graphqlOperation } from "aws-amplify";
  import { listStaff } from "@/graphql/queries";
  import { createStaff, updateStaff } from "@/graphql/mutations";

  import TextInput from "@/components/form/TextInput";
  import SelectInput from "@/components/form/SelectInput";

  export default {
    name: 'StaffTab',

    components: {
      TextInput,
      SelectInput
    },

    data() {
      return {

        form: {
          id: null,
          name: '',
          department: null
        },

        formDepartmentOptions: [
          { value: 'none', text: 'None' },
          { value: 'sales', text: 'Sales' },
          { value: 'customer-service', text: 'Customer service' },
          { value: 'delivery-team', text: 'Delivery team' },
        ],

        departmentTexts: {
          'none': 'None',
          'sales': 'Sales',
          'customer-service': 'Customer service',
          'delivery-team': 'Delivery team',
        },

        dbRows: [],

        nameFilter: '',

        departmentFilter: null,

      };
    },

    methods: {

      loadDbRows() {
        API.graphql(graphqlOperation(listStaff)).then((result) => {
          this.dbRows = result.data.listStaff.items;
        });
      },

      formSubmit() {
        if (this.anyErrors === false) {

          if (this.form.id === null) {
            // CREATE

            const dataToSubmit = {
              name: this.form.name,
              department: this.form.department
            };
            console.log('create', dataToSubmit);

            API.graphql(graphqlOperation(createStaff, { input: dataToSubmit })).then((createResult) => {
              console.log('create -> result', createResult);
              Swal.fire('Saved!', '', 'success')
              this.formClear();
              this.loadDbRows();
            });

          } else {
            //EDIT

            const dataToSubmit = {
              id: this.form.id,
              name: this.form.name,
              department: this.form.department
            };

            console.log('update', dataToSubmit);

            API.graphql(graphqlOperation(updateStaff, { input: dataToSubmit })).then((updateResult) => {
              console.log('update -> result', updateResult);
              Swal.fire('Saved!', '', 'success')
              this.formClear();
              this.loadDbRows();
            });

          }

        }

      },

      formClear() {
        this.form = {
          id: null,
          name: '',
          department: null
        };
      },

      formFillFromRow(dbRow) {
        this.form = {
          id: dbRow.id,
          name: dbRow.name,
          department: dbRow.department
        };
      }

    },

    computed: {

      formErrors: function () {
        return {
          name: (this.form.name.length > 0 ? false : 'Please fill out'),
          department: (this.form.department !== null ? false : 'Please select'),
        };
      },

      anyErrors: function () {

        return (
          this.formErrors.name !== false ||
          this.formErrors.department !== false
        );

      },

      dbRowsFiltered: function () {
        let nameFilterLower = this.nameFilter.toLowerCase();

        return this.dbRows.filter((el) => {

          let match = true;

          if (this.departmentFilter !== null) {
            if (el.department !== this.departmentFilter) {
              match = false;
            }
          }

          if (nameFilterLower.length > 0) {
            if (!el.name.toLowerCase().includes(nameFilterLower)) {
              match = false;
            }
          }

          return match;
        });
      }

    },

    mounted() {
      this.formClear();
      this.loadDbRows();
    },
  }
</script>

<style scoped>

</style>
