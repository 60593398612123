<script setup>
  import { Authenticator } from "@aws-amplify/ui-vue";
  import "@aws-amplify/ui-vue/styles.css";

  import Amplify from "aws-amplify";
  import awsconfig from "./aws-exports";

  Amplify.configure(awsconfig);

</script>

<template>
  <div class="container">
    <authenticator :hide-sign-up="true" class="d-flex justify-content-center mt-5">
      <template v-slot="{ user, signOut }">

        <div class="text-right my-3">
          Logged in as {{ user.signInUserSession.idToken.payload.given_name }} - <a href="#" @click="signOut">Sign Out</a>
        </div>

        <div class="card">
          <div class="card-body">

            <h3 class="text-center">Board Stats</h3>

            <div class="row">
              <div class="col text-center">
                <button type="button" :class="navButtonClass(TAB_TYPE.DASHBOARD)" @click="setTab(TAB_TYPE.DASHBOARD)">Dashboard</button>

                <button type="button" :class="navButtonClass(TAB_TYPE.FINANCIAL)" @click="setTab(TAB_TYPE.FINANCIAL)">Financial</button>
                <button type="button" :class="navButtonClass(TAB_TYPE.SALES)" @click="setTab(TAB_TYPE.SALES)">Sales</button>
                <button type="button" :class="navButtonClass(TAB_TYPE.MARKETING)" @click="setTab(TAB_TYPE.MARKETING)">Marketing</button>
                <button type="button" :class="navButtonClass(TAB_TYPE.CUSTOMER)" @click="setTab(TAB_TYPE.CUSTOMER)">Customer</button>
                <button type="button" :class="navButtonClass(TAB_TYPE.SERVICE_DELIVERY)" @click="setTab(TAB_TYPE.SERVICE_DELIVERY)">Service Delivery</button>
                <button type="button" :class="navButtonClass(TAB_TYPE.ISO)" @click="setTab(TAB_TYPE.ISO)">ISO</button>

                <button type="button" :class="navButtonClass(TAB_TYPE.STAFF, false)" @click="setTab(TAB_TYPE.STAFF)">Staff</button>
              </div>
            </div>

            <hr/>

            <div class="row" v-if="tab === TAB_TYPE.DASHBOARD">
              <div class="col">
                <DashboardTab></DashboardTab>
              </div>
            </div>

            <div class="row" v-else-if="tab === TAB_TYPE.STAFF">
              <div class="col">
                <StaffTab></StaffTab>
              </div>
            </div>

            <div class="row" v-else-if="tab === TAB_TYPE.FINANCIAL">
              <div class="col">
                <FinancialTab></FinancialTab>
              </div>
            </div>

            <div class="row" v-else-if="tab === TAB_TYPE.SALES">
              <div class="col">
                <SalesTab></SalesTab>
              </div>
            </div>

            <div class="row" v-else-if="tab === TAB_TYPE.MARKETING">
              <div class="col">
                <MarketingTab></MarketingTab>
              </div>
            </div>

            <div class="row" v-else-if="tab === TAB_TYPE.CUSTOMER">
              <div class="col">
                <CustomerTab></CustomerTab>
              </div>
            </div>

            <div class="row" v-else-if="tab === TAB_TYPE.SERVICE_DELIVERY">
              <div class="col">
                <ServiceDeliveryTab></ServiceDeliveryTab>
              </div>
            </div>

            <div class="row" v-else-if="tab === TAB_TYPE.ISO">
              <div class="col">
                <IsoTab></IsoTab>
              </div>
            </div>

          </div>
        </div>

      </template>
    </authenticator>
  </div>
</template>

<script>
  import DashboardTab from "@/components/DashboardTab";
  import StaffTab from "@/components/StaffTab";
  import FinancialTab from "@/components/FinancialTab";
  import SalesTab from "@/components/SalesTab";
  import MarketingTab from "@/components/MarketingTab";
  import CustomerTab from "@/components/CustomerTab";
  import ServiceDeliveryTab from "@/components/ServiceDeliveryTab";
  import IsoTab from "@/components/IsoTab";

  const tabTypes = {
    DASHBOARD: 0,
    STAFF: 1,
    FINANCIAL: 2,
    SALES: 3,
    MARKETING: 4,
    CUSTOMER: 5,
    SERVICE_DELIVERY: 6,
    ISO: 7
  }

  export default {
    name: "App",
    data() {
      return {
        TAB_TYPE: tabTypes,
        tab: 0,
      };
    },
    methods: {
      navButtonClass(tabIndex, rightMargin = true) {
        return {
          btn: true,
          "btn-outline-warning": this.tab !== tabIndex,
          "btn-warning": this.tab === tabIndex,
          "mr-2": rightMargin,
        };
      },
      setTab(tabIndex) {
        this.tab = tabIndex;
      },
      signOut() {
        console.log("signout");
      },
    },
    components: {
      DashboardTab,
      StaffTab,
      FinancialTab,
      SalesTab,
      MarketingTab,
      CustomerTab,
      ServiceDeliveryTab,
      IsoTab,
    },
  };
</script>

<style>
  body {
    background-color: #c1d109 !important;
  }
  .amplify-button[data-variation='primary'] {
    background: rgb(255, 153, 0);
  }
  .amplify-button[data-variation='primary']:hover {
    background: rgb(255, 173, 78);
  }
</style>
