<template>
  <div>
    <h3>Customer Breakdown</h3>


    <div v-for="(dbRow, i) in dbCustomerBreakdownRowsSorted" :key="i">
      <div class="card mb-3">
        <div class="card-header">
          {{ dbRow.date }}
          <button class="btn btn-outline-warning btn-sm float-right" @click="formCustomerBreakdownFillFromRow(dbRow)">Edit</button>
        </div>
        <div class="card-body">

          <table class="table">
            <thead>
              <tr>
                <th>Staff</th>
                <th>Support</th>
                <th>Communication</th>
                <th>Positive Impact</th>
                <th>Overall Performance</th>
                <th>Value for Money</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, j) in dbRow.breakdowns.items" :key="j">
                <td>{{ item.staff.name }}</td>
                <td>{{ item.support }}</td>
                <td>{{ item.communication }}</td>
                <td>{{ item.positiveImpact }}</td>
                <td>{{ item.overallPerformance }}</td>
                <td>{{ item.valueForMoney }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>



    <div class="card mb-3" ref="formCustomerBreakdown">
      <div class="card-body">
        <year-month-input
            label="Date"
            :labelWidth="2"
            :inputMonthWidth="5"
            :inputYearWidth="5"
            v-model:modelMonthValue="formCustomerBreakdown.dateMonth"
            v-model:modelYearValue="formCustomerBreakdown.dateYear"
        ></year-month-input>

        <div class="form-group row" min="-1">
          <label class="col-sm-2 col-form-label">Staff</label>
          <div class="col-sm-10">

            <table class="table">
              <thead>
                <tr>
                  <th class="rotate" style="width: 150px"><div><span>Staff</span></div></th>
                  <th class="rotate"><div><span>Support</span></div></th>
                  <th class="rotate"><div><span>Communication</span></div></th>
                  <th class="rotate"><div><span>Positive Impact</span></div></th>
                  <th class="rotate"><div><span>Overall Performance</span></div></th>
                  <th class="rotate"><div><span>Value for Money</span></div></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in formCustomerBreakdown.breakdowns" :key="i" :class="{'table-danger':item.markedDeleted}">
                  <td>
                    <select-input
                        label="a"
                        :selectOptions="formStaffOptions"
                        v-model="item.staff"
                        :noWrap="true"
                    ></select-input>
                  </td>
                  <td>
                    <number-input v-model="item.support" min="-1" :noWrap="true"></number-input>
                  </td>
                  <td>
                    <number-input v-model="item.communication" min="-1" :noWrap="true"></number-input>
                  </td>
                  <td>
                    <number-input v-model="item.positiveImpact" min="-1" :noWrap="true"></number-input>
                  </td>
                  <td>
                    <number-input v-model="item.overallPerformance" min="-1" :noWrap="true"></number-input>
                  </td>
                  <td>
                    <number-input v-model="item.valueForMoney" min="-1" :noWrap="true"></number-input>
                  </td>
                  <td>
                    <button
                        :class="{'btn':true, 'btn-block':true, 'btn-danger':item.markedDeleted, 'btn-outline-danger':!item.markedDeleted}"
                        @click="item.markedDeleted = !item.markedDeleted"
                    >X</button>
                  </td>
                </tr>
                <tr>
                  <td colspan="8">
                    <button class="btn btn-block btn-outline-warning" @click="formCustomerBreakdownAddBreakdown">Add</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="form-group row mb-0">
          <div class="offset-sm-2 col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formCustomerBreakdownSubmit">Create / Update</button>
          </div>
          <div class="col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formCustomerBreakdownClear">Clear</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import Swal from 'sweetalert2';

  import { API, graphqlOperation } from "aws-amplify";
  import {
    getCustomerBreakdown,
    listStaff
  } from "@/graphql/queries";
  import { listCustomerBreakdownsComplete } from "@/graphql/custom/queries";
  import {
    createCustomerBreakdown,
    createCustomerBreakdownForStaff,
    deleteCustomerBreakdownForStaff,
    updateCustomerBreakdownForStaff
  } from "@/graphql/mutations";

  import NumberInput from "@/components/form/NumberInput";
  import YearMonthInput from "@/components/form/YearMonthInput";
  import SelectInput from "@/components/form/SelectInput";

  export default {
    name: 'CustomerTab',

    components: {
      YearMonthInput,
      NumberInput,
      SelectInput
    },

    data() {
      return {

        formCustomerBreakdown: {
          dateMonth: 1,
          dateYear: 2022,
          breakdowns: []
        },

        dbStaffRows: [],
        dbCustomerBreakdownRows: [],

      }
    },

    methods: {

      scrollToElement(refName) {
        let element = this.$refs[refName];
        let top = element.offsetTop;

        window.scrollTo(0, top);
      },

      // ---

      loadStaffDbRows() {
        API.graphql(graphqlOperation(listStaff, {
          filter: {
            department: {eq: "customer-service"}
          }
        })).then((result) => {
          this.dbStaffRows = result.data.listStaff.items;
          this.formCustomerBreakdownClear(); // Update it now we have staff.
        });
      },

      // ---

      loadCustomerBreakdownDbRows() {
        API.graphql(graphqlOperation(listCustomerBreakdownsComplete)).then((result) => {
          this.dbCustomerBreakdownRows = result.data.listCustomerBreakdowns.items;
          this.formCustomerBreakdownClear();
        });
      },

      async formCustomerBreakdownSubmit() {

        const dataToSubmit = {
          date: this.formCustomerBreakdown.dateMonth + "/" + this.formCustomerBreakdown.dateYear,
        };
        const existingResult = await API.graphql(graphqlOperation(getCustomerBreakdown, { date: dataToSubmit.date }));
        if (existingResult.data.getCustomerBreakdown === null) {

          console.log('create', dataToSubmit);

          const createResult = await API.graphql(graphqlOperation(createCustomerBreakdown, { input: dataToSubmit }));
          console.log('create -> result', createResult);

          if (createResult.data.createCustomerBreakdown !== null) {

            for (let i=0; i<this.formCustomerBreakdown.breakdowns.length; i++) {
              let bdObj = this.formCustomerBreakdown.breakdowns[i];
              if (bdObj.staff !== null) {


                let breakdownDataToSubmit = {
                  customerBreakdownBreakdownsId: dataToSubmit.date,
                  customerBreakdownForStaffStaffId: bdObj.staff.id,

                  support: bdObj.support,
                  communication: bdObj.communication,
                  positiveImpact: bdObj.positiveImpact,
                  overallPerformance: bdObj.overallPerformance,
                  valueForMoney: bdObj.valueForMoney,

                };

                console.log('create (breakdown)', breakdownDataToSubmit);
                let createBreakdownResult = await API.graphql(graphqlOperation(createCustomerBreakdownForStaff, { input: breakdownDataToSubmit }))
                console.log('create (breakdown) -> result', createBreakdownResult);

              }
            }

            Swal.fire('Saved!', '', 'success')
            this.formCustomerBreakdownClear();
            this.loadCustomerBreakdownDbRows();
          }

          console.log('create -> complete');

        } else {

          console.log('update', existingResult);

          for (let i=0; i<this.formCustomerBreakdown.breakdowns.length; i++) {
            let bdObj = this.formCustomerBreakdown.breakdowns[i];
            if (bdObj.staff !== null) {

              let breakdownDataToSubmit = {
                customerBreakdownBreakdownsId: dataToSubmit.date,
                customerBreakdownForStaffStaffId: bdObj.staff.id,

                support: bdObj.support,
                communication: bdObj.communication,
                positiveImpact: bdObj.positiveImpact,
                overallPerformance: bdObj.overallPerformance,
                valueForMoney: bdObj.valueForMoney,
              };


              let breakdownUpdateDataToSubmit = {
                id: bdObj.id,
                ...breakdownDataToSubmit
              }

              if (bdObj.markedDeleted === true) {
                if (bdObj.id !== null) {
                  console.log('delete (breakdown)', breakdownUpdateDataToSubmit);
                  let deleteBreakdownResult = await API.graphql(graphqlOperation(deleteCustomerBreakdownForStaff, {input:{id: bdObj.id}}));
                  console.log('delete (breakdown) -> result', deleteBreakdownResult);
                }
              } else {
                if (bdObj.id !== null) {
                  console.log('update (breakdown)', breakdownUpdateDataToSubmit);
                  let updateBreakdownResult = await API.graphql(graphqlOperation(updateCustomerBreakdownForStaff, {input: breakdownUpdateDataToSubmit}))
                  console.log('update (breakdown) -> result', updateBreakdownResult);
                } else {
                  console.log('create (breakdown)', breakdownDataToSubmit);
                  let createBreakdownResult = await API.graphql(graphqlOperation(createCustomerBreakdownForStaff, {input: breakdownDataToSubmit}))
                  console.log('create (breakdown) -> result', createBreakdownResult);
                }
              }

            }
          }

          Swal.fire('Saved!', '', 'success')
          this.formCustomerBreakdownClear();
          this.loadCustomerBreakdownDbRows();

        }

      },

      formCustomerBreakdownAddBreakdown(staff = null) {
        this.formCustomerBreakdown.breakdowns.push({
          id: null,
          staff: staff,

          support: 0,
          communication: 0,
          positiveImpact: 0,
          overallPerformance: 0,
          valueForMoney: 0,

          markedDeleted: false,
        });
      },

      formCustomerBreakdownClear() {
        let today = new Date();
        let todayMonth = (today.getMonth() + 1);
        let todayYear = (today.getFullYear());

        this.formCustomerBreakdown = {
          dateMonth: todayMonth,
          dateYear: todayYear,
          breakdowns: []
        };

        for (let si=0; si<this.dbStaffRows.length; si++) {
          let staff = this.dbStaffRows[si];
          this.formCustomerBreakdownAddBreakdown(staff);
        }
      },

      formCustomerBreakdownFillFromRow(dbRow) {

        let dateSplit = dbRow.date.split("/");
        let breakdowns = [];

        for (let i=0; i<dbRow.breakdowns.items.length; i++) {
          let breakdownRow = dbRow.breakdowns.items[i];

          let localStaff = this.dbStaffById[breakdownRow.staff.id] || breakdownRow.staff

          breakdowns.push({
            id: breakdownRow.id,
            staff: localStaff,

            support: breakdownRow.support,
            communication: breakdownRow.communication,
            positiveImpact: breakdownRow.positiveImpact,
            overallPerformance: breakdownRow.overallPerformance,
            valueForMoney: breakdownRow.valueForMoney,

            markedDeleted: false,
          });
        }

        this.formCustomerBreakdown = {
          dateMonth: parseInt(dateSplit[0]),
          dateYear: parseInt(dateSplit[1]),
          breakdowns: breakdowns
        }

        this.scrollToElement("formCustomerBreakdown");

      },
    },

    computed: {

      dbCustomerBreakdownRowsSorted: function() {
        return [...this.dbCustomerBreakdownRows].sort((a, b) => {

          let dateASpl = a.date.split("/");
          let dateBSpl = b.date.split("/");

          let dateA = (new Date(parseInt(dateASpl[1]), parseInt(dateASpl[0]) - 1, 1));
          let dateB = (new Date(parseInt(dateBSpl[1]), parseInt(dateBSpl[0]) - 1, 1));

          return dateA > dateB ? 1 : -1
        });
      },

      dbStaffById: function() {
        let opts = {};
        for (let i=0; i<this.dbStaffRows.length; i++) {
          let staff = this.dbStaffRows[i];
          opts[staff.id] = staff;
        }
        return opts;
      },

      formStaffOptions: function() {
        let opts = [];
        for (let i=0; i<this.dbStaffRows.length; i++) {
          let staff = this.dbStaffRows[i];
          opts.push({
            value: staff,
            text: staff.name
          });
        }
        return opts;
      }

    },

    mounted() {

      this.loadStaffDbRows();

      this.formCustomerBreakdownClear();
      this.loadCustomerBreakdownDbRows();

    },

  }
</script>

<style scoped>

  th.rotate {
    height: 140px;
    white-space: nowrap;
    padding: 0 !important;
  }

  th.rotate > div {
    transform:
        translate(13px, 0px)
        rotate(310deg);
    width: 30px;
  }

  th.rotate > div > span {
    padding: 5px 10px;
  }

</style>
