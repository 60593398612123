<template>
  <div>

    <div class="row mb-2">
      <div class="col text-center">
        <button type="button" :class="navButtonClass(TAB_TYPE.MAIN)" @click="setTab(TAB_TYPE.MAIN)">Main</button>
        <button type="button" :class="navButtonClass(TAB_TYPE.SALES_STAFF)" @click="setTab(TAB_TYPE.SALES_STAFF)">Sales</button>
        <button type="button" :class="navButtonClass(TAB_TYPE.CUSTOMER_SERVICE_STAFF)" @click="setTab(TAB_TYPE.CUSTOMER_SERVICE_STAFF)">Customer Service</button>
        <button type="button" :class="navButtonClass(TAB_TYPE.SERVICE_DELIVERY_STAFF, false)" @click="setTab(TAB_TYPE.SERVICE_DELIVERY_STAFF)">Service Delivery</button>
      </div>
    </div>

    <div class="row" v-if="tab === TAB_TYPE.MAIN">
      <div class="col">

        <h3>Dashboard</h3>

        <div class="row">
          <div class="col-6">

            <div class="card h-100">
              <div class="card-header">
                <div style="height: 60px" class="text-center">
                  <img src="@/assets/customerService.png" style="height: 100%"/>
                </div>
                <div class="text-center">Customer Service</div>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col">
                    <table class="table table-sm text-center mb-0">
                      <thead>
                      <tr>
                        <th></th>
                        <th>Client</th>
                        <th>Employee</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{{ lastMonthText }}</td>
                        <td>{{ customerServiceBoxData.month.client !== false ? (round2Dp(customerServiceBoxData.month.client) + '%') : 'N/A' }}</td>
                        <td>{{ customerServiceBoxData.month.employee !== false ? (round2Dp(customerServiceBoxData.month.employee) + '%') : 'N/A' }}</td>
                      </tr>
                      <tr>
                        <td>YTD</td>
                        <td>{{ round2Dp(customerServiceBoxData.ytd.client) }}%</td>
                        <td>{{ round2Dp(customerServiceBoxData.ytd.employee) }}%</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Line
                        :chart-options="customerServiceGraphOptions"
                        :chart-data="customerServiceGraphData"
                        :height="300"
                    />
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="col-6">

            <div class="card h-100">
              <div class="card-header">
                <div style="height: 60px" class="text-center">
                  <img src="@/assets/iso.png" style="height: 100%"/>
                </div>
                <div class="text-center">ISO</div>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col">
                    <table class="table table-sm text-center mb-0">
                      <thead>
                      <tr>
                        <th></th>
                        <th>Complaints</th>
                        <th>Imp log</th>
                        <th>CAR's</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{{ lastMonthText }}</td>
                        <td>{{ isoBoxData.month !== false ? isoBoxData.month.complaints : 'N/A' }}</td>
                        <td>{{ isoBoxData.month !== false ? isoBoxData.month.impLog : 'N/A' }}</td>
                        <td>{{ isoBoxData.month !== false ? isoBoxData.month.cars : 'N/A' }}</td>
                      </tr>
                      <tr>
                        <td>YTD</td>
                        <td>{{ isoBoxData.ytd.complaints }}</td>
                        <td>{{ isoBoxData.ytd.impLog }}</td>
                        <td>{{ isoBoxData.ytd.cars }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Line
                        :chart-options="isoGraphOptions"
                        :chart-data="isoGraphData"
                        :height="300"
                    />
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">

            <div class="card h-100">
              <div class="card-header">
                <div style="height: 60px" class="text-center">
                  <img src="@/assets/financial.png" style="height: 100%"/>
                </div>
                <div class="text-center">Financial</div>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col">
                    <table class="table table-sm text-center mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Revenue</th>
                          <th>Net Profit</th>
                          <th>Budget</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>YTD</td>
                          <td>{{ moneyFormat(financialBoxData.ytd.revenue) }}</td>
                          <td>{{ moneyFormat(financialBoxData.ytd.netProfit) }}</td>
                          <td>{{ moneyFormat(financialBoxData.ytd.budget) }}</td>
                        </tr>
                        <tr>
                          <td>% Budget</td>
                          <td>{{ round2Dp(financialBoxData.budgetPer.revenue) }}%</td>
                          <td>{{ round2Dp(financialBoxData.budgetPer.netProfit) }}%</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Line
                        :chart-options="financialGraphOptions"
                        :chart-data="financialGraphData"
                        :height="300"
                    />
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="col-6">

            <div class="card h-100">
              <div class="card-header">
                <div style="height: 60px" class="text-center">
                  <img src="@/assets/marketing.png" style="height: 100%"/>
                </div>
                <div class="text-center">Marketing</div>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col">
                    <table class="table table-sm text-center mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Web</th>
                          <th>Campaign</th>
                          <th>Social Media</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ lastMonthText }}</td>
                          <td>{{ marketingBoxData.month !== false ? marketingBoxData.month.webEnquiry : 'N/A' }}</td>
                          <td>{{ marketingBoxData.month !== false ? marketingBoxData.month.campaignEnquiry : 'N/A' }}</td>
                          <td>{{ marketingBoxData.month !== false ? marketingBoxData.month.socialMedia : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <td>YTD</td>
                          <td>{{ marketingBoxData.ytd.webEnquiry }}</td>
                          <td>{{ marketingBoxData.ytd.campaignEnquiry }}</td>
                          <td>{{ marketingBoxData.ytd.socialMedia }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Line
                        :chart-options="marketingGraphOptions"
                        :chart-data="marketingGraphData"
                        :height="300"
                    />
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div class="row mt-3">
          <div class="col">

            <div class="card">
              <div class="card-header">
                <div style="height: 60px" class="text-center">
                  <img src="@/assets/sales.png" style="height: 100%"/>
                </div>
                <div class="text-center">Sales</div>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col">
                    <table class="table table-sm text-center mb-0">
                      <thead>
                      <tr>
                        <th></th>
                        <th>EHA</th>
                        <th>Pascal</th>
                        <th>MWC</th>
                        <th>DSE</th>
                        <th>DFV</th>
                        <th>OnD/Cal</th>
                        <th>W/Shop & Promo</th>
                        <th>Other</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>YTD</td>
                        <th>{{ moneyFormat(salesBoxData.ytd.eha) }}</th>
                        <th>{{ moneyFormat(salesBoxData.ytd.pascal) }}</th>
                        <th>{{ moneyFormat(salesBoxData.ytd.mwc) }}</th>
                        <th>{{ moneyFormat(salesBoxData.ytd.healthyWorkstation) }}</th>
                        <th>{{ moneyFormat(salesBoxData.ytd.dfv) }}</th>
                        <th>{{ moneyFormat(salesBoxData.ytd.onDemandCalendars) }}</th>
                        <th>{{ moneyFormat(salesBoxData.ytd.workshopsPromotion) }}</th>
                        <th>{{ moneyFormat(salesBoxData.ytd.other) }}</th>
                      </tr>
                      <tr>
                        <td>% Budget</td>
                        <th>{{ round2Dp(salesBoxData.budgetPercent.eha) }}%</th>
                        <th>{{ round2Dp(salesBoxData.budgetPercent.pascal) }}%</th>
                        <th>{{ round2Dp(salesBoxData.budgetPercent.mwc) }}%</th>
                        <th>{{ round2Dp(salesBoxData.budgetPercent.healthyWorkstation) }}%</th>
                        <th>{{ round2Dp(salesBoxData.budgetPercent.dfv) }}%</th>
                        <th>{{ round2Dp(salesBoxData.budgetPercent.onDemandCalendars) }}%</th>
                        <th>{{ round2Dp(salesBoxData.budgetPercent.workshopsPromotion) }}%</th>
                        <th>{{ round2Dp(salesBoxData.budgetPercent.other) }}%</th>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Line
                        :chart-options="salesGraphOptions"
                        :chart-data="salesGraphData"
                        :height="300"
                    />
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="row" v-if="tab === TAB_TYPE.SALES_STAFF">
      <div class="col">

        <h3>Dashboard - Sales</h3>

        <div class="row">
          <div class="col-12">

            <div class="card mt-3" v-for="(staffName, staffId) in salesStaffData" :key="staffId">
              <div class="card-header">
                <div class="text-center">{{ staffName }}</div>
              </div>
              <div class="card-body">
                <Line
                    :chart-options="salesBreakdownGraphOptions"
                    :chart-data="salesBreakdownGraphData[staffId]"
                    :height="300"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="tab === TAB_TYPE.CUSTOMER_SERVICE_STAFF">
      <div class="col">

        <h3>Dashboard - Customer Service</h3>

        <div class="row">
          <div class="col-12">

            <div class="card mt-3">
              <div class="card-header">
                <div class="text-center">Team Average</div>
              </div>
              <div class="card-body">
                <Line
                    :chart-options="customerServiceBreakdownTeamAverageGraphOptions"
                    :chart-data="customerServiceBreakdownTeamAverageGraphData"
                    :height="300"
                />
              </div>
            </div>

            <div class="card mt-3" v-for="(staffName, staffId) in customerServiceStaffData" :key="staffId">
              <div class="card-header">
                <div class="text-center">{{ staffName }}</div>
              </div>
              <div class="card-body">
                <Line
                    :chart-options="customerServiceBreakdownGraphOptions"
                    :chart-data="customerServiceBreakdownGraphData[staffId]"
                    :height="300"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="tab === TAB_TYPE.SERVICE_DELIVERY_STAFF">
      <div class="col">

        <h3>Dashboard - Service Delivery</h3>

        <div class="row">
          <div class="col-12">

            <div class="card mt-3">
              <div class="card-header">
                <div class="text-center">Team Average</div>
              </div>
              <div class="card-body">
                <Line
                    :chart-options="serviceDeliveryBreakdownTeamAverageGraphOptions"
                    :chart-data="serviceDeliveryBreakdownTeamAverageGraphData"
                    :height="300"
                />
              </div>
            </div>

            <div class="card mt-3" v-for="(staffName, staffId) in serviceDeliveryStaffData" :key="staffId">
              <div class="card-header">
                <div class="text-center">{{ staffName }}</div>
              </div>
              <div class="card-body">
                <Line
                    :chart-options="serviceDeliveryBreakdownGraphOptions"
                    :chart-data="serviceDeliveryBreakdownGraphData[staffId]"
                    :height="300"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

  import { API, graphqlOperation } from "aws-amplify";
  import {
    getCustomerBreakdown,
    getFinancial,
    getIso,
    getMarketing,
    getSales, getSalesBreakdown,
    getServiceDeliveryBreakdown, listStaff
  } from "@/graphql/queries";

  import { Line } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,

    LineElement,
    PointElement,

    //BarElement,

    //Plugin,

  } from 'chart.js';

  ChartJS.register(
      Title,
      Tooltip,
      Legend,
      CategoryScale,
      LinearScale,

      LineElement,
      PointElement,

      //BarElement,
  )

  const tabTypes = {
    MAIN: 0,
    SALES_STAFF: 1,
    CUSTOMER_SERVICE_STAFF: 2,
    SERVICE_DELIVERY_STAFF: 3,
  }

  export default {
    name: 'DashboardTab',

    data() {
      return {

        TAB_TYPE: tabTypes,
        tab: 0,

        dbSalesStaffData: {},
        dbCustomerServiceStaffData: {},
        dbServiceDeliveryStaffData: {},

        dbIsoData: {},
        dbMarketingData: {},
        dbFinancialData: {},
        dbCustomerBreakdownData: {},
        dbServiceDeliveryBreakdownData: {},
        dbSalesData: {},
        dbSalesBreakdownData: {},

        monthsFull: ["January","February","March","April","May","June","July", "August","September","October","November","December"],
        monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"],

        isoGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        marketingGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        financialGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        customerServiceGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        customerServiceBreakdownGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        customerServiceBreakdownTeamAverageGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        salesGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        salesBreakdownGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        serviceDeliveryBreakdownGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        serviceDeliveryBreakdownTeamAverageGraphOptions: {
          responsive: true,
          maintainAspectRatio: false
        },

      };
    },

    components: {
      Line,
      //Bar,
    },

    methods: {

      navButtonClass(tabIndex, rightMargin = true) {
        return {
          btn: true,
          "btn-outline-warning": this.tab !== tabIndex,
          "btn-warning": this.tab === tabIndex,
          "mr-2": rightMargin,
        };
      },

      setTab(tabIndex) {
        this.tab = tabIndex;
      },

      loadDbRows() {

        this.dbSalesStaffData = {};
        this.dbCustomerServiceStaffData = {};
        this.dbServiceDeliveryStaffData = {};

        this.dbIsoData = {};
        this.dbMarketingData = {};
        this.dbFinancialData = {};
        this.dbCustomerBreakdownData = {};
        this.dbServiceDeliveryBreakdownData = {};
        this.dbSalesData = {};
        this.dbSalesBreakdownData = {};

        API.graphql(graphqlOperation(listStaff, {
          filter: {
            department: { eq: "sales" }
          }
        })).then((result) => {
          this.dbSalesStaffData = result.data.listStaff.items;
        });

        API.graphql(graphqlOperation(listStaff, {
          filter: {
            department: { eq: "customer-service" }
          }
        })).then((result) => {
          this.dbCustomerServiceStaffData = result.data.listStaff.items;
        });

        API.graphql(graphqlOperation(listStaff, {
          filter: {
            department: { eq: "delivery-team" }
          }
        })).then((result) => {
          this.dbServiceDeliveryStaffData = result.data.listStaff.items;
        });

        for (let i=0; i<this.currentDate.month; i++) {
          let date = this.loopDate(i);
          let dataKey = this.loopKey(i);

          API.graphql(graphqlOperation(getIso, {date: date})).then((result) => {
            if (result.data.getIso !== null) {
              this.dbIsoData[dataKey] = result.data.getIso;
            }
          });

          API.graphql(graphqlOperation(getMarketing, { date: date })).then((result) => {
            if (result.data.getMarketing !== null) {
              this.dbMarketingData[dataKey] = result.data.getMarketing;
            }
          });

          API.graphql(graphqlOperation(getFinancial, { date: date })).then((result) => {
            if (result.data.getFinancial !== null) {
              this.dbFinancialData[dataKey] = result.data.getFinancial;
            }
          });

          API.graphql(graphqlOperation(getCustomerBreakdown, { date: date })).then((result) => {
            if (result.data.getCustomerBreakdown !== null) {
              this.dbCustomerBreakdownData[dataKey] = result.data.getCustomerBreakdown;
            }
          });

          API.graphql(graphqlOperation(getServiceDeliveryBreakdown, { date: date })).then((result) => {
            if (result.data.getServiceDeliveryBreakdown !== null) {
              this.dbServiceDeliveryBreakdownData[dataKey] = result.data.getServiceDeliveryBreakdown;
            }
          });

          API.graphql(graphqlOperation(getSales, { date: date })).then((result) => {
            if (result.data.getSales !== null) {
              this.dbSalesData[dataKey] = result.data.getSales;
            }
          });

          API.graphql(graphqlOperation(getSalesBreakdown, { date: date })).then((result) => {
            if (result.data.getSalesBreakdown !== null) {
              this.dbSalesBreakdownData[dataKey] = result.data.getSalesBreakdown;
            }
          });

        }

      },

      round2Dp(number) {
        return (Math.round(number * 100) / 100);
      },

      moneyFormat(money) {
        return (new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', maximumFractionDigits: 0 }).format(money));
      },

      loopDate(month) {
        if (month === 0) {
          return "12/" + (this.currentDate.year - 1);
        }
        return month + "/" + this.currentDate.year;
      },

      loopKey(month) {
        if (month === 0) {
          return "12_" + (this.currentDate.year - 1);
        }
        return month + "_" + this.currentDate.year;
      },

      monthTextFull(month) {
        let monthIndex = ((12 + (month - 1)) % 12);
        return this.monthsFull[monthIndex];
      },

      monthTextShort(month) {
        let monthIndex = ((12 + (month - 1)) % 12);
        return this.monthsShort[monthIndex];
      },

    },

    computed: {

      currentDate: () => {
        let today = new Date();
        let month = (today.getMonth() + 1);
        let year = (today.getFullYear());
        return {
          month: month,
          year: year,
        };
      },

      lastMonthText: function() {
        return this.monthTextFull(this.currentDate.month - 1);
      },

      // ----

      salesStaffData: function() {
        let blob = {};

        for (let si=0; si<this.dbSalesStaffData.length; si++) {
          let loopMStaffData = this.dbSalesStaffData[si];
          blob[loopMStaffData.id] = loopMStaffData.name;
        }

        return blob;
      },

      customerServiceStaffData: function() {
        let blob = {};

        for (let si=0; si<this.dbCustomerServiceStaffData.length; si++) {
          let loopMStaffData = this.dbCustomerServiceStaffData[si];
          blob[loopMStaffData.id] = loopMStaffData.name;
        }

        return blob;
      },

      serviceDeliveryStaffData: function() {
        let blob = {};

        for (let si=0; si<this.dbServiceDeliveryStaffData.length; si++) {
          let loopMStaffData = this.dbServiceDeliveryStaffData[si];
          blob[loopMStaffData.id] = loopMStaffData.name;
        }

        return blob;
      },

      // ----

      isoData: function() {
        let blob = {};

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.dbIsoData[loopMKey] !== undefined) {
            let loopMData = this.dbIsoData[loopMKey];
            blob[loopMKey] = {
              complaints: loopMData.clientComplaints || 0,
              impLog: loopMData.improvementLogOpen || 0,
              cars: loopMData.carsRaised || 0,
            };
          }
        }

        return blob;
      },

      marketingData: function() {
        let blob = {};

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.dbMarketingData[loopMKey] !== undefined) {
            let loopMData = this.dbMarketingData[loopMKey];
            blob[loopMKey] = {
              webEnquiry: loopMData.websiteEnquiries || 0,
              campaignEnquiry: loopMData.campaignEnquiries || 0,
              socialMedia: loopMData.socialMediaFollowers || 0,
            };
          }
        }

        return blob;
      },

      financialData: function() {
        let blob = {};

        for (let mi=1; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.dbFinancialData[loopMKey] !== undefined) {
            let loopMData = this.dbFinancialData[loopMKey];
            blob[loopMKey] = {
              revenue: loopMData.revenue || 0,
              netProfit: loopMData.profitLoss || 0,
              budget: loopMData.budget || 0,
            };
          }
        }

        return blob;
      },

      customerServiceData: function() {
        let blob = {};

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          let loopMData = {
            client: false,
            employee: false,
          };

          if (this.dbCustomerBreakdownData[loopMKey] !== undefined) {
            let monthRow = this.dbCustomerBreakdownData[loopMKey];
            let monthPercent = 0;
            let monthPercentEntries = 0;
            for (let si=0; si<monthRow.breakdowns.items.length; si++) {
              let staffRow = monthRow.breakdowns.items[si];
              let staffPercent = (staffRow.overallPerformance || 0);
              if (staffPercent >= 0) {
                monthPercent += staffPercent;
                monthPercentEntries++;
              }
            }
            if (monthPercentEntries > 0) {
              loopMData.client = monthPercent / monthPercentEntries;
            }
          }

          if (this.dbServiceDeliveryBreakdownData[loopMKey] !== undefined) {
            let monthRow = this.dbServiceDeliveryBreakdownData[loopMKey];
            let monthPercent = 0;
            let monthPercentEntries = 0;
            for (let si=0; si<monthRow.breakdowns.items.length; si++) {
              let staffRow = monthRow.breakdowns.items[si];
              let staffPercent = (staffRow.assessmentRating || 0);
              if (staffPercent >= 0) {
                monthPercent += staffPercent;
                monthPercentEntries++;
              }
            }
            if (monthPercentEntries > 0) {
              loopMData.employee = monthPercent / monthPercentEntries;
            }
          }

          blob[loopMKey] = loopMData;
        }
        return blob;
      },

      customerServiceBreakdownData: function() {

        let blob = {};

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.dbCustomerBreakdownData[loopMKey] !== undefined) {
            let loopMData = this.dbCustomerBreakdownData[loopMKey];
            let byStaffId = {};
            for (let si=0; si<loopMData.breakdowns.items.length; si++) {
              let loopMStaffData = loopMData.breakdowns.items[si];
              byStaffId[loopMStaffData.customerBreakdownForStaffStaffId] = {
                support: loopMStaffData.support,
                communication: loopMStaffData.communication,
                positiveImpact: loopMStaffData.positiveImpact,
                overallPerformance: loopMStaffData.overallPerformance,
                valueForMoney: loopMStaffData.valueForMoney,
              };
            }
            blob[loopMKey] = byStaffId;
          }
        }

        return blob;
      },

      salesData: function() {
        let blob = {};

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.dbSalesData[loopMKey] !== undefined) {
            let loopMData = this.dbSalesData[loopMKey];
            blob[loopMKey] = {
              month: {
                eha: (loopMData.eha || 0),
                mwc: (loopMData.mwc || 0),
                pascal: (loopMData.pascal || 0),
                healthyWorkstation: (loopMData.healthyWorkstation || 0),
                dfv: (loopMData.dfv || 0),
                onDemandCalendars: (loopMData.onDemandCalendars || 0),
                workshopsPromotion: (loopMData.workshopsPromotion || 0),
                other: (loopMData.other || 0),
              },
              budget: {
                eha: (loopMData.ehaBudget || 0),
                mwc: (loopMData.mwcBudget || 0),
                pascal: (loopMData.pascalBudget || 0),
                healthyWorkstation: (loopMData.healthyWorkstationBudget || 0),
                dfv: (loopMData.dfvBudget || 0),
                onDemandCalendars: (loopMData.onDemandCalendarsBudget || 0),
                workshopsPromotion: (loopMData.workshopsPromotionBudget || 0),
                other: (loopMData.otherBudget || 0),
              },
            };
          }
        }

        return blob;
      },

      salesBreakdownData: function() {
        let blob = {};

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.dbSalesBreakdownData[loopMKey] !== undefined) {
            let loopMData = this.dbSalesBreakdownData[loopMKey];
            let byStaffId = {};
            for (let si=0; si<loopMData.breakdowns.items.length; si++) {
              let loopMStaffData = loopMData.breakdowns.items[si];
              byStaffId[loopMStaffData.salesBreakdownForStaffStaffId] = {
                sales: loopMStaffData.sales,
                pipeline: loopMStaffData.pipeline,
              };
            }
            blob[loopMKey] = byStaffId;
          }
        }

        return blob;
      },

      serviceDeliveryBreakdownData: function() {

        let blob = {};

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.dbServiceDeliveryBreakdownData[loopMKey] !== undefined) {
            let loopMData = this.dbServiceDeliveryBreakdownData[loopMKey];
            let byStaffId = {};
            for (let si=0; si<loopMData.breakdowns.items.length; si++) {
              let loopMStaffData = loopMData.breakdowns.items[si];
              byStaffId[loopMStaffData.serviceDeliveryBreakdownForStaffStaffId] = {
                onsiteDays: loopMStaffData.onsiteDays,
                startOnTime: loopMStaffData.startOnTime,
                enoughTime: loopMStaffData.enoughTime,
                lifestyleChanges: loopMStaffData.lifestyleChanges,
                professionalism: loopMStaffData.professionalism,
                assessmentRating: loopMStaffData.assessmentRating,
                recommend: loopMStaffData.recommend,
              };
            }
            blob[loopMKey] = byStaffId;
          }
        }

        return blob;
      },

      // ----

      isoBoxData: function() {

        let blob = {
          month: false,
          ytd: {
            complaints: 0,
            impLog: 0,
            cars: 0,
          }
        };

        let mKey = this.loopKey(this.currentDate.month - 1);
        if (this.isoData[mKey] !== undefined) {
          blob.month = this.isoData[mKey];
        }

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.isoData[loopMKey] !== undefined) {
            blob.ytd.complaints += this.isoData[loopMKey].complaints;
            blob.ytd.impLog += this.isoData[loopMKey].impLog;
            blob.ytd.cars += this.isoData[loopMKey].cars;
          }
        }

        return blob;
      },

      marketingBoxData: function() {

        let blob = {
          month: false,
          ytd: {
            webEnquiry: 0,
            campaignEnquiry: 0,
            socialMedia: 0,
          }
        };

        let mKey = this.loopKey(this.currentDate.month - 1);
        if (this.marketingData[mKey] !== undefined) {
          blob.month = this.marketingData[mKey];
        }

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.marketingData[loopMKey] !== undefined) {
            blob.ytd.webEnquiry += this.marketingData[loopMKey].webEnquiry;
            blob.ytd.campaignEnquiry += this.marketingData[loopMKey].campaignEnquiry;
            blob.ytd.socialMedia += this.marketingData[loopMKey].socialMedia;
          }
        }

        return blob;
      },

      financialBoxData: function() {

        let blob = {
          ytd: {
            revenue: 0,
            netProfit: 0,
            budget: 0,
          },
          budgetPer: {
            revenue: 0,
            netProfit: 0,
          },
          yearPer: ((100 / 12) * this.currentDate.month)
        };

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.financialData[loopMKey] !== undefined) {
            blob.ytd.revenue += this.financialData[loopMKey].revenue;
            blob.ytd.netProfit += this.financialData[loopMKey].netProfit;
            blob.ytd.budget += this.financialData[loopMKey].budget;
          }
        }

        if (blob.ytd.budget > 0) {
          blob.budgetPer.revenue = ((100 / blob.ytd.budget) * blob.ytd.revenue);
        }

        if (blob.ytd.budget > 0) {
          blob.budgetPer.netProfit = ((100 / blob.ytd.budget) * blob.ytd.netProfit);
        }

        return blob;
      },

      customerServiceBoxData: function() {

        let blob = {
          month: {
            client: false,
            employee: false,
          },
          ytd: {
            client: false,
            employee: false,
          },
        };

        let mKey = this.loopKey(this.currentDate.month - 1);
        if (this.customerServiceData[mKey] !== undefined) {
          blob.month = this.customerServiceData[mKey];
        }

        let totalClientPercent = 0;
        let totalClientPercentEntries = 0;

        let totalEmployeePercent = 0;
        let totalEmployeePercentEntries = 0;

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.customerServiceData[loopMKey] !== undefined) {
            let monthRow = this.customerServiceData[loopMKey];
            if (monthRow.client !== false) {
              if (monthRow.client >= 0) {
                totalClientPercent += monthRow.client;
                totalClientPercentEntries++;
              }
            }
            if (monthRow.employee !== false) {
              if (monthRow.employee >= 0) {
                totalEmployeePercent += monthRow.employee;
                totalEmployeePercentEntries++;
              }
            }
          }
        }

        if (totalClientPercentEntries > 0) {
          blob.ytd.client = totalClientPercent / totalClientPercentEntries;
        }

        if (totalEmployeePercentEntries > 0) {
          blob.ytd.employee = totalEmployeePercent / totalEmployeePercentEntries;
        }

        return blob;
      },

      salesBoxData: function() {

        let blob = {

          ytd: {
            eha: 0,
            mwc: 0,
            pascal: 0,
            healthyWorkstation: 0,
            dfv: 0,
            onDemandCalendars: 0,
            workshopsPromotion: 0,
            other: 0,
          },

          budgetYtd: {
            eha: 0,
            mwc: 0,
            pascal: 0,
            healthyWorkstation: 0,
            dfv: 0,
            onDemandCalendars: 0,
            workshopsPromotion: 0,
            other: 0,
          },

          budgetPercent: {
            eha: 0,
            mwc: 0,
            pascal: 0,
            healthyWorkstation: 0,
            dfv: 0,
            onDemandCalendars: 0,
            workshopsPromotion: 0,
            other: 0,
          },

          yearPer: ((100 / 12) * this.currentDate.month)
        };

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          if (this.salesData[loopMKey] !== undefined) {
            let monthRow = this.salesData[loopMKey];

            blob.ytd.eha += monthRow.month.eha;
            blob.ytd.mwc += monthRow.month.mwc;
            blob.ytd.pascal += monthRow.month.pascal;
            blob.ytd.healthyWorkstation += monthRow.month.healthyWorkstation;
            blob.ytd.dfv += monthRow.month.dfv;
            blob.ytd.onDemandCalendars += monthRow.month.onDemandCalendars;
            blob.ytd.workshopsPromotion += monthRow.month.workshopsPromotion;
            blob.ytd.other += monthRow.month.other;

            blob.budgetYtd.eha += monthRow.budget.eha;
            blob.budgetYtd.mwc += monthRow.budget.mwc;
            blob.budgetYtd.pascal += monthRow.budget.pascal;
            blob.budgetYtd.healthyWorkstation += monthRow.budget.healthyWorkstation;
            blob.budgetYtd.dfv += monthRow.budget.dfv;
            blob.budgetYtd.onDemandCalendars += monthRow.budget.onDemandCalendars;
            blob.budgetYtd.workshopsPromotion += monthRow.budget.workshopsPromotion;
            blob.budgetYtd.other += monthRow.budget.other;

          }
        }

        blob.budgetPercent.eha = (blob.budgetYtd.eha > 0 ? ((100 / blob.budgetYtd.eha) * blob.ytd.eha) : 0);
        blob.budgetPercent.mwc = (blob.budgetYtd.mwc > 0 ? ((100 / blob.budgetYtd.mwc) * blob.ytd.mwc) : 0);
        blob.budgetPercent.pascal = (blob.budgetYtd.pascal > 0 ? ((100 / blob.budgetYtd.pascal) * blob.ytd.pascal) : 0);
        blob.budgetPercent.healthyWorkstation = (blob.budgetYtd.healthyWorkstation > 0 ? ((100 / blob.budgetYtd.healthyWorkstation) * blob.ytd.healthyWorkstation) : 0);
        blob.budgetPercent.dfv = (blob.budgetYtd.dfv > 0 ? ((100 / blob.budgetYtd.dfv) * blob.ytd.dfv) : 0);
        blob.budgetPercent.onDemandCalendars = (blob.budgetYtd.onDemandCalendars > 0 ? ((100 / blob.budgetYtd.onDemandCalendars) * blob.ytd.onDemandCalendars) : 0);
        blob.budgetPercent.workshopsPromotion = (blob.budgetYtd.workshopsPromotion > 0 ? ((100 / blob.budgetYtd.workshopsPromotion) * blob.ytd.workshopsPromotion) : 0);
        blob.budgetPercent.other = (blob.budgetYtd.other > 0 ? ((100 / blob.budgetYtd.other) * blob.ytd.other) : 0);

        return blob;

      },

      //salesBreakdownBoxData

      // ----

      isoGraphData: function() {

        let labels = [];
        let dataComplaints = [];
        let dataImpLog = [];
        let dataCars = [];

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          let monthText = this.monthTextShort(mi);
          labels.push(monthText);
          if (this.isoData[loopMKey] !== undefined) {
            dataComplaints.push(this.isoData[loopMKey].complaints);
            dataImpLog.push(this.isoData[loopMKey].impLog);
            dataCars.push(this.isoData[loopMKey].cars);
          } else {
            dataComplaints.push(0);
            dataImpLog.push(0);
            dataCars.push(0);
          }
        }

        return {
          labels: labels,
          datasets: [
              {
                label: 'Complaints',
                backgroundColor: '#f87979',
                data: dataComplaints
              },
              {
                label: 'Imp Log',
                backgroundColor: '#7df879',
                data: dataImpLog
              },
              {
                label: 'CARs',
                backgroundColor: '#214cfd',
                data: dataCars
              }
          ]
        };
      },

      marketingGraphData: function() {

        //marketingData
        let labels = [];
        let dataWebEnquiry = [];
        let dataCampaignEnquiry = [];
        let dataSocialMedia = [];

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          let monthText = this.monthTextShort(mi);
          labels.push(monthText);
          if (this.marketingData[loopMKey] !== undefined) {
            dataWebEnquiry.push(this.marketingData[loopMKey].webEnquiry);
            dataCampaignEnquiry.push(this.marketingData[loopMKey].campaignEnquiry);
            dataSocialMedia.push(this.marketingData[loopMKey].socialMedia);
          } else {
            dataWebEnquiry.push(0);
            dataCampaignEnquiry.push(0);
            dataSocialMedia.push(0);
          }
        }

        return {
          labels: labels,
          datasets: [
            {
              label: 'Web',
              backgroundColor: '#f8ed79',
              data: dataWebEnquiry
            },
            {
              label: 'Campaign',
              backgroundColor: '#f879df',
              data: dataCampaignEnquiry
            },
            {
              label: 'Social media',
              backgroundColor: '#ee965f',
              data: dataSocialMedia
            }
          ]
        };

      },

      financialGraphData: function() {

        let labels = [];
        let dataBudget = [];
        let dataRevenue = [];
        let dataNetProfit = [];

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          let monthText = this.monthTextShort(mi);
          labels.push(monthText);
          if (this.financialData[loopMKey] !== undefined) {
            let monthData = this.financialData[loopMKey];
            dataBudget.push(monthData.budget);
            dataRevenue.push(monthData.revenue); //this.round2Dp(monthData.budget > 0 ? ((100 / monthData.budget) * monthData.revenue) : 0));
            dataNetProfit.push(monthData.netProfit); //this.round2Dp(monthData.budget > 0 ? ((100 / monthData.budget) * monthData.netProfit) : 0));
          } else {
            dataBudget.push(0);
            dataRevenue.push(0);
            dataNetProfit.push(0);
          }
        }

        return {
          labels: labels,
          datasets: [
            {
              label: 'Budget',
              backgroundColor: '#a379f8',
              data: dataBudget
            },
            {
              label: 'Revenue',
              backgroundColor: '#f8ed79',
              data: dataRevenue
            },
            {
              label: 'Net Profit',
              backgroundColor: '#7df879',
              data: dataNetProfit
            },
          ]
        };

      },

      customerServiceGraphData: function() {

        let labels = [];
        let dataClient = [];
        let dataEmployee = [];

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          let monthText = this.monthTextShort(mi);
          labels.push(monthText);
          if (this.customerServiceData[loopMKey] !== undefined) {
            let monthData = this.customerServiceData[loopMKey];
            dataClient.push(monthData.client !== false ? monthData.client : 0 );
            dataEmployee.push(monthData.employee !== false ? monthData.employee : 0 );
          } else {
            dataClient.push(0);
            dataEmployee.push(0);
          }
        }

        return {
          labels: labels,
          datasets: [
            {
              label: 'Client',
              backgroundColor: '#a379f8',
              data: dataClient
            },
            {
              label: 'Employee',
              backgroundColor: '#79f4f8',
              data: dataEmployee
            },
          ]
        };

      },

      customerServiceBreakdownGraphData: function() {

        let graphs = {};

        let staffIds = Object.keys(this.customerServiceStaffData);
        for (let si=0; si<staffIds.length; si++) {
          let staffId = staffIds[si];

          let labels = [];
          let data = {
            support: [],
            communication: [],
            positiveImpact: [],
            overallPerformance: [],
            valueForMoney: [],
          };

          for (let mi=0; mi<this.currentDate.month; mi++) {

            let loopMKey = this.loopKey(mi);
            let monthText = this.monthTextShort(mi);
            labels.push(monthText);

            let tempData = {
              support: 0,
              communication: 0,
              positiveImpact: 0,
              overallPerformance: 0,
              valueForMoney: 0
            };

            if (this.customerServiceBreakdownData[loopMKey] !== undefined) {
              let monthData = this.customerServiceBreakdownData[loopMKey];
              if (monthData[staffId] !== undefined) {
                let monthStaffData = monthData[staffId];
                tempData.support = monthStaffData.support >= 0 ? monthStaffData.support : 0;
                tempData.communication = monthStaffData.communication >= 0 ? monthStaffData.communication : 0;
                tempData.positiveImpact = monthStaffData.positiveImpact >= 0 ? monthStaffData.positiveImpact : 0;
                tempData.overallPerformance = monthStaffData.overallPerformance >= 0 ? monthStaffData.overallPerformance : 0;
                tempData.valueForMoney = monthStaffData.valueForMoney >= 0 ? monthStaffData.valueForMoney : 0;
              }
            }

            data.support.push(tempData.support);
            data.communication.push(tempData.communication);
            data.positiveImpact.push(tempData.positiveImpact);
            data.overallPerformance.push(tempData.overallPerformance);
            data.valueForMoney.push(tempData.valueForMoney);

          }

          graphs[staffId] = {
            labels: labels,
            datasets: [
              {
                label: 'Support',
                backgroundColor: '#f87979',
                data: data.support
              },
              {
                label: 'Communication',
                backgroundColor: '#a379f8',
                data: data.communication
              },
              {
                label: 'Positive Impact',
                backgroundColor: '#f8ed79',
                data: data.positiveImpact
              },
              {
                label: 'Overall Performance',
                backgroundColor: '#f879df',
                data: data.overallPerformance
              },
              {
                label: 'Value For Money',
                backgroundColor: '#ee965f',
                data: data.valueForMoney
              }
            ]
          };

        }

        return graphs;

      },

      customerServiceBreakdownTeamAverageGraphData: function() {

        let labels = [];
        let data = {
          support: [],
          communication: [],
          positiveImpact: [],
          overallPerformance: [],
          valueForMoney: [],
        };

        for (let mi=0; mi<this.currentDate.month; mi++) {

          let loopMKey = this.loopKey(mi);
          let monthText = this.monthTextShort(mi);
          labels.push(monthText);

          let tempDataTotal = {
            support: 0,
            communication: 0,
            positiveImpact: 0,
            overallPerformance: 0,
            valueForMoney: 0
          };

          let tempDataEntries = {
            support: 0,
            communication: 0,
            positiveImpact: 0,
            overallPerformance: 0,
            valueForMoney: 0
          };

          let staffIds = Object.keys(this.customerServiceStaffData);
          for (let si=0; si<staffIds.length; si++) {
            let staffId = staffIds[si];
            if (this.customerServiceBreakdownData[loopMKey] !== undefined) {
              let monthData = this.customerServiceBreakdownData[loopMKey];
              if (monthData[staffId] !== undefined) {
                let monthStaffData = monthData[staffId];

                if (monthStaffData.support >= 0) {
                  tempDataTotal.support += monthStaffData.support;
                  tempDataEntries.support++;
                }
                if (monthStaffData.communication >= 0) {
                  tempDataTotal.communication += monthStaffData.communication;
                  tempDataEntries.communication++;
                }
                if (monthStaffData.positiveImpact >= 0) {
                  tempDataTotal.positiveImpact += monthStaffData.positiveImpact;
                  tempDataEntries.positiveImpact++;
                }
                if (monthStaffData.overallPerformance >= 0) {
                  tempDataTotal.overallPerformance += monthStaffData.overallPerformance;
                  tempDataEntries.overallPerformance++;
                }
                if (monthStaffData.valueForMoney >= 0) {
                  tempDataTotal.valueForMoney += monthStaffData.valueForMoney;
                  tempDataEntries.valueForMoney++;
                }
              }
            }
          }

          data.support.push(tempDataEntries.support > 0 ? (tempDataTotal.support / tempDataEntries.support): 0);
          data.communication.push(tempDataEntries.communication > 0 ? (tempDataTotal.communication / tempDataEntries.communication): 0);
          data.positiveImpact.push(tempDataEntries.positiveImpact > 0 ? (tempDataTotal.positiveImpact / tempDataEntries.positiveImpact): 0);
          data.overallPerformance.push(tempDataEntries.overallPerformance > 0 ? (tempDataTotal.overallPerformance / tempDataEntries.overallPerformance): 0);
          data.valueForMoney.push(tempDataEntries.valueForMoney > 0 ? (tempDataTotal.valueForMoney / tempDataEntries.valueForMoney): 0);

        }

        return {
          labels: labels,
          datasets: [
            {
              label: 'Support',
              backgroundColor: '#f87979',
              data: data.support
            },
            {
              label: 'Communication',
              backgroundColor: '#a379f8',
              data: data.communication
            },
            {
              label: 'Positive Impact',
              backgroundColor: '#f8ed79',
              data: data.positiveImpact
            },
            {
              label: 'Overall Performance',
              backgroundColor: '#f879df',
              data: data.overallPerformance
            },
            {
              label: 'Value For Money',
              backgroundColor: '#ee965f',
              data: data.valueForMoney
            }
          ]
        };

      },

      salesGraphData: function() {

        let labels = [];
        let data = {
          eha: [],
          mwc: [],
          pascal: [],
          healthyWorkstation: [],
          dfv: [],
          onDemandCalendars: [],
          workshopsPromotion: [],
          other: [],
        }

        for (let mi=0; mi<this.currentDate.month; mi++) {
          let loopMKey = this.loopKey(mi);
          let monthText = this.monthTextShort(mi);
          labels.push(monthText);
          if (this.salesData[loopMKey] !== undefined) {
            let monthData = this.salesData[loopMKey];
            data.eha.push(this.round2Dp(monthData.budget.eha > 0 ? ((100 / monthData.budget.eha) * monthData.month.eha) : 0));
            data.mwc.push(this.round2Dp(monthData.budget.mwc > 0 ? ((100 / monthData.budget.mwc) * monthData.month.mwc) : 0));
            data.pascal.push(this.round2Dp(monthData.budget.pascal > 0 ? ((100 / monthData.budget.pascal) * monthData.month.pascal) : 0));
            data.healthyWorkstation.push(this.round2Dp(monthData.budget.healthyWorkstation > 0 ? ((100 / monthData.budget.healthyWorkstation) * monthData.month.healthyWorkstation) : 0));
            data.dfv.push(this.round2Dp(monthData.budget.dfv > 0 ? ((100 / monthData.budget.dfv) * monthData.month.dfv) : 0));
            data.onDemandCalendars.push(this.round2Dp(monthData.budget.onDemandCalendars > 0 ? ((100 / monthData.budget.onDemandCalendars) * monthData.month.onDemandCalendars) : 0));
            data.workshopsPromotion.push(this.round2Dp(monthData.budget.workshopsPromotion > 0 ? ((100 / monthData.budget.workshopsPromotion) * monthData.month.workshopsPromotion) : 0));
            data.other.push(this.round2Dp(monthData.budget.other > 0 ? ((100 / monthData.budget.other) * monthData.month.other) : 0));
          } else {
            data.eha.push(0);
            data.mwc.push(0);
            data.pascal.push(0);
            data.healthyWorkstation.push(0);
            data.dfv.push(0);
            data.onDemandCalendars.push(0);
            data.workshopsPromotion.push(0);
            data.other.push(0);
          }
        }

        return {
          labels: labels,
          datasets: [
            {
              label: 'EHA',
              backgroundColor: '#f87979',
              data: data.eha
            },
            {
              label: 'MWC',
              backgroundColor: '#7df879',
              data: data.mwc
            },
            {
              label: 'Pascal',
              backgroundColor: '#214cfd',
              data: data.pascal
            },
            {
              label: 'DSE',
              backgroundColor: '#f8ed79',
              data: data.healthyWorkstation
            },
            {
              label: 'DFV',
              backgroundColor: '#f879df',
              data: data.dfv
            },
            {
              label: 'OnD/Cal',
              backgroundColor: '#ee965f',
              data: data.onDemandCalendars
            },
            {
              label: 'Workshops promotion',
              backgroundColor: '#a379f8',
              data: data.workshopsPromotion
            },
            {
              label: 'Other',
              backgroundColor: '#79f4f8',
              data: data.other
            },
          ]
        };

      },

      salesBreakdownGraphData: function() {

        let graphs = {};

        let staffIds = Object.keys(this.salesStaffData);
        for (let si=0; si<staffIds.length; si++) {
          let staffId = staffIds[si];

          let labels = [];
          let data = {
            sales: [],
            pipeline: [],
          };

          for (let mi=0; mi<this.currentDate.month; mi++) {
            let loopMKey = this.loopKey(mi);
            let monthText = this.monthTextShort(mi);
            labels.push(monthText);

            let tempData = {
              sales: 0,
              pipeline: 0
            };

            if (this.salesBreakdownData[loopMKey] !== undefined) {
              let monthData = this.salesBreakdownData[loopMKey];
              if (monthData[staffId] !== undefined) {
                let monthStaffData = monthData[staffId];
                tempData.sales = monthStaffData.sales;
                tempData.pipeline = monthStaffData.pipeline;
              }
            }

            data.sales.push(tempData.sales);
            data.pipeline.push(tempData.pipeline);

          }

          graphs[staffId] = {
            labels: labels,
            datasets: [
              {
                label: 'Sales',
                backgroundColor: '#f87979',
                data: data.sales
              },
              {
                label: 'Pipeline',
                backgroundColor: '#f8ed79',
                data: data.pipeline
              },
            ]
          };
        }

        return graphs;

      },

      serviceDeliveryBreakdownGraphData: function() {

        let graphs = {};

        let staffIds = Object.keys(this.serviceDeliveryStaffData);
        for (let si=0; si<staffIds.length; si++) {
          let staffId = staffIds[si];

          let labels = [];
          let data = {
            onsiteDays: [],
            startOnTime: [],
            enoughTime: [],
            lifestyleChanges: [],
            professionalism: [],
            assessmentRating: [],
            recommend: [],
          };

          for (let mi=0; mi<this.currentDate.month; mi++) {

            let loopMKey = this.loopKey(mi);
            let monthText = this.monthTextShort(mi);
            labels.push(monthText);

            let tempData = {
              onsiteDays: 0,
              startOnTime: 0,
              enoughTime: 0,
              lifestyleChanges: 0,
              professionalism: 0,
              assessmentRating: 0,
              recommend: 0,
            };

            if (this.serviceDeliveryBreakdownData[loopMKey] !== undefined) {
              let monthData = this.serviceDeliveryBreakdownData[loopMKey];
              if (monthData[staffId] !== undefined) {
                let monthStaffData = monthData[staffId];

                tempData.onsiteDays = monthStaffData.onsiteDays >= 0 ? monthStaffData.onsiteDays : 0;
                tempData.startOnTime = monthStaffData.startOnTime >= 0 ? monthStaffData.startOnTime : 0;
                tempData.enoughTime = monthStaffData.enoughTime >= 0 ? monthStaffData.enoughTime : 0;
                tempData.lifestyleChanges = monthStaffData.lifestyleChanges >= 0 ? monthStaffData.lifestyleChanges : 0;
                tempData.professionalism = monthStaffData.professionalism >= 0 ? monthStaffData.professionalism : 0;
                tempData.assessmentRating = monthStaffData.assessmentRating >= 0 ? monthStaffData.assessmentRating : 0;
                tempData.recommend = monthStaffData.recommend >= 0 ? monthStaffData.recommend : 0;

              }
            }

            data.onsiteDays.push(tempData.onsiteDays);
            data.startOnTime.push(tempData.startOnTime);
            data.enoughTime.push(tempData.enoughTime);
            data.lifestyleChanges.push(tempData.lifestyleChanges);
            data.professionalism.push(tempData.professionalism);
            data.assessmentRating.push(tempData.assessmentRating);
            data.recommend.push(tempData.recommend);

          }

          graphs[staffId] = {
            labels: labels,
            datasets: [
              {
                label: 'Onsite Days',
                backgroundColor: '#f87979',
                data: data.onsiteDays
              },
              {
                label: 'Start On Time',
                backgroundColor: '#a379f8',
                data: data.startOnTime
              },
              {
                label: 'Enough Time',
                backgroundColor: '#f8ed79',
                data: data.enoughTime
              },
              {
                label: 'Lifestyle Changes',
                backgroundColor: '#f879df',
                data: data.lifestyleChanges
              },
              {
                label: 'Professionalism',
                backgroundColor: '#ee965f',
                data: data.professionalism
              },
              {
                label: 'Assessment Rating',
                backgroundColor: '#79f4f8',
                data: data.assessmentRating
              },
              {
                label: 'Recommend',
                backgroundColor: '#7df879',
                data: data.recommend
              },
            ]
          };

        }

        return graphs;
      },

      serviceDeliveryBreakdownTeamAverageGraphData: function() {

        let labels = [];
        let data = {
          onsiteDays: [],
          startOnTime: [],
          enoughTime: [],
          lifestyleChanges: [],
          professionalism: [],
          assessmentRating: [],
          recommend: [],
        };

        for (let mi=0; mi<this.currentDate.month; mi++) {

          let loopMKey = this.loopKey(mi);
          let monthText = this.monthTextShort(mi);
          labels.push(monthText);

          let tempDataTotal = {
            onsiteDays: 0,
            startOnTime: 0,
            enoughTime: 0,
            lifestyleChanges: 0,
            professionalism: 0,
            assessmentRating: 0,
            recommend: 0,
          };

          let tempDataEntries = {
            onsiteDays: 0,
            startOnTime: 0,
            enoughTime: 0,
            lifestyleChanges: 0,
            professionalism: 0,
            assessmentRating: 0,
            recommend: 0,
          };

          let staffIds = Object.keys(this.serviceDeliveryStaffData);
          for (let si=0; si<staffIds.length; si++) {
            let staffId = staffIds[si];

            if (this.serviceDeliveryBreakdownData[loopMKey] !== undefined) {
              let monthData = this.serviceDeliveryBreakdownData[loopMKey];
              if (monthData[staffId] !== undefined) {
                let monthStaffData = monthData[staffId];

                if (monthStaffData.onsiteDays >= 0) {
                  tempDataTotal.onsiteDays += monthStaffData.onsiteDays;
                  tempDataEntries.onsiteDays++;
                }
                if (monthStaffData.startOnTime >= 0) {
                  tempDataTotal.startOnTime += monthStaffData.startOnTime;
                  tempDataEntries.startOnTime++;
                }
                if (monthStaffData.enoughTime >= 0) {
                  tempDataTotal.enoughTime += monthStaffData.enoughTime;
                  tempDataEntries.enoughTime++;
                }
                if (monthStaffData.lifestyleChanges >= 0) {
                  tempDataTotal.lifestyleChanges += monthStaffData.lifestyleChanges;
                  tempDataEntries.lifestyleChanges++;
                }
                if (monthStaffData.professionalism >= 0) {
                  tempDataTotal.professionalism += monthStaffData.professionalism;
                  tempDataEntries.professionalism++;
                }
                if (monthStaffData.assessmentRating >= 0) {
                  tempDataTotal.assessmentRating += monthStaffData.assessmentRating;
                  tempDataEntries.assessmentRating++;
                }
                if (monthStaffData.recommend >= 0) {
                  tempDataTotal.recommend += monthStaffData.recommend;
                  tempDataEntries.recommend++;
                }
              }
            }
          }

          data.onsiteDays.push(tempDataEntries.onsiteDays > 0 ? (tempDataTotal.onsiteDays / tempDataEntries.onsiteDays): 0);
          data.startOnTime.push(tempDataEntries.startOnTime > 0 ? (tempDataTotal.startOnTime / tempDataEntries.startOnTime): 0);
          data.enoughTime.push(tempDataEntries.enoughTime > 0 ? (tempDataTotal.enoughTime / tempDataEntries.enoughTime): 0);
          data.lifestyleChanges.push(tempDataEntries.lifestyleChanges > 0 ? (tempDataTotal.lifestyleChanges / tempDataEntries.lifestyleChanges): 0);
          data.professionalism.push(tempDataEntries.professionalism > 0 ? (tempDataTotal.professionalism / tempDataEntries.professionalism): 0);
          data.assessmentRating.push(tempDataEntries.assessmentRating > 0 ? (tempDataTotal.assessmentRating / tempDataEntries.assessmentRating): 0);
          data.recommend.push(tempDataEntries.recommend > 0 ? (tempDataTotal.recommend / tempDataEntries.recommend): 0);

        }

        return {
          labels: labels,
          datasets: [
            {
              label: 'Onsite Days',
              backgroundColor: '#f87979',
              data: data.onsiteDays
            },
            {
              label: 'Start On Time',
              backgroundColor: '#a379f8',
              data: data.startOnTime
            },
            {
              label: 'Enough Time',
              backgroundColor: '#f8ed79',
              data: data.enoughTime
            },
            {
              label: 'Lifestyle Changes',
              backgroundColor: '#f879df',
              data: data.lifestyleChanges
            },
            {
              label: 'Professionalism',
              backgroundColor: '#ee965f',
              data: data.professionalism
            },
            {
              label: 'Assessment Rating',
              backgroundColor: '#79f4f8',
              data: data.assessmentRating
            },
            {
              label: 'Recommend',
              backgroundColor: '#7df879',
              data: data.recommend
            },
          ]
        };
      },

      // ----
    },

    mounted() {
      this.loadDbRows();
    },

  }
</script>

<style scoped>

</style>
