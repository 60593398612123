<template>
  <div>
    <h3>Service Delivery Breakdown</h3>

    <div v-for="(dbRow, i) in dbServiceDeliveryBreakdownRowsSorted" :key="i">
      <div class="card mb-3">
        <div class="card-header">
          {{ dbRow.date }}
          <button class="btn btn-outline-warning btn-sm float-right" @click="formServiceDeliveryBreakdownFillFromRow(dbRow)">Edit</button>
        </div>
        <div class="card-body">

          <table class="table">
            <thead>
              <tr>
                <th>Staff</th>
                <th>Onsite Days</th>
                <th>Start on Time</th>
                <th>Enough Time</th>
                <th>Lifestyle Changes</th>
                <th>Professionalism</th>
                <th>Assessment Rating</th>
                <th>Recommend</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, j) in dbRow.breakdowns.items" :key="j">
                <td>{{ item.staff.name }}</td>
                <td>{{ item.onsiteDays }}</td>
                <td>{{ item.startOnTime }}</td>
                <td>{{ item.enoughTime }}</td>
                <td>{{ item.lifestyleChanges }}</td>
                <td>{{ item.professionalism }}</td>
                <td>{{ item.assessmentRating }}</td>
                <td>{{ item.recommend }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card mb-3" ref="formServiceDeliveryBreakdown">
      <div class="card-body">
        <year-month-input
            label="Date"
            :labelWidth="2"
            :inputMonthWidth="5"
            :inputYearWidth="5"
            v-model:modelMonthValue="formServiceDeliveryBreakdown.dateMonth"
            v-model:modelYearValue="formServiceDeliveryBreakdown.dateYear"
        ></year-month-input>

        <div class="form-group row" min="-1">
          <label class="col-sm-2 col-form-label">Staff</label>
          <div class="col-sm-10">

            <table class="table">
              <thead>
              <tr>
                <th class="rotate" style="width: 150px"><div><span>Staff</span></div></th>
                <th class="rotate"><div><span>Onsite Days</span></div></th>
                <th class="rotate"><div><span>Start on Time</span></div></th>
                <th class="rotate"><div><span>Enough Time</span></div></th>
                <th class="rotate"><div><span>Lifestyle Changes</span></div></th>
                <th class="rotate"><div><span>Professionalism</span></div></th>
                <th class="rotate"><div><span>Assessment Rating</span></div></th>
                <th class="rotate"><div><span>Recommend</span></div></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in formServiceDeliveryBreakdown.breakdowns" :key="i" :class="{'table-danger':item.markedDeleted}">
                <td>
                  <select-input
                      label="a"
                      :selectOptions="formStaffOptions"
                      v-model="item.staff"
                      :noWrap="true"
                  ></select-input>
                </td>
                <td>
                  <number-input v-model="item.onsiteDays" min="-1" :noWrap="true"></number-input>
                </td>
                <td>
                  <number-input v-model="item.startOnTime" min="-1" :noWrap="true"></number-input>
                </td>
                <td>
                  <number-input v-model="item.enoughTime" min="-1" :noWrap="true"></number-input>
                </td>
                <td>
                  <number-input v-model="item.lifestyleChanges" min="-1" :noWrap="true"></number-input>
                </td>
                <td>
                  <number-input v-model="item.professionalism" min="-1" :noWrap="true"></number-input>
                </td>
                <td>
                  <number-input v-model="item.assessmentRating" min="-1" :noWrap="true"></number-input>
                </td>
                <td>
                  <number-input v-model="item.recommend" min="-1" :noWrap="true"></number-input>
                </td>
                <td>
                  <button
                      :class="{'btn':true, 'btn-block':true, 'btn-danger':item.markedDeleted, 'btn-outline-danger':!item.markedDeleted}"
                      @click="item.markedDeleted = !item.markedDeleted"
                  >X</button>
                </td>
              </tr>
              <tr>
                <td colspan="9">
                  <button class="btn btn-block btn-outline-warning" @click="formServiceDeliveryBreakdownAddBreakdown">Add</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="form-group row mb-0">
          <div class="offset-sm-2 col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formServiceDeliveryBreakdownSubmit">Create / Update</button>
          </div>
          <div class="col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formServiceDeliveryBreakdownClear">Clear</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import Swal from 'sweetalert2';

  import { API, graphqlOperation } from "aws-amplify";
  import {
    getServiceDeliveryBreakdown,
    listStaff
  } from "@/graphql/queries";
  import { listServiceDeliveryBreakdownsComplete } from "@/graphql/custom/queries";
  import {
    createServiceDeliveryBreakdown,
    createServiceDeliveryBreakdownForStaff,
    deleteServiceDeliveryBreakdownForStaff,
    updateServiceDeliveryBreakdownForStaff
  } from "@/graphql/mutations";

  import NumberInput from "@/components/form/NumberInput";
  import YearMonthInput from "@/components/form/YearMonthInput";
  import SelectInput from "@/components/form/SelectInput";

  export default {
    name: 'ServiceDeliveryTab',

    components: {
      YearMonthInput,
      NumberInput,
      SelectInput
    },

    data() {
      return {

        formServiceDeliveryBreakdown: {
          dateMonth: 1,
          dateYear: 2022,
          breakdowns: []
        },

        dbStaffRows: [],
        dbServiceDeliveryBreakdownRows: [],

      }
    },

    methods: {

      scrollToElement(refName) {
        let element = this.$refs[refName];
        let top = element.offsetTop;

        window.scrollTo(0, top);
      },

      // ---

      loadStaffDbRows() {
        API.graphql(graphqlOperation(listStaff, {
          filter: {
            department: {eq: "delivery-team"}
          }
        })).then((result) => {
          this.dbStaffRows = result.data.listStaff.items;
          this.formServiceDeliveryBreakdownClear();
        });
      },

      // ---

      loadServiceDeliveryBreakdownDbRows() {
        API.graphql(graphqlOperation(listServiceDeliveryBreakdownsComplete)).then((result) => {
          this.dbServiceDeliveryBreakdownRows = result.data.listServiceDeliveryBreakdowns.items;
          this.formServiceDeliveryBreakdownClear();
        });
      },

      async formServiceDeliveryBreakdownSubmit() {

        const dataToSubmit = {
          date: this.formServiceDeliveryBreakdown.dateMonth + "/" + this.formServiceDeliveryBreakdown.dateYear,
        };
        const existingResult = await API.graphql(graphqlOperation(getServiceDeliveryBreakdown, { date: dataToSubmit.date }));
        if (existingResult.data.getServiceDeliveryBreakdown === null) {

          console.log('create', dataToSubmit);

          const createResult = await API.graphql(graphqlOperation(createServiceDeliveryBreakdown, { input: dataToSubmit }));
          console.log('create -> result', createResult);

          if (createResult.data.createServiceDeliveryBreakdown !== null) {

            for (let i=0; i<this.formServiceDeliveryBreakdown.breakdowns.length; i++) {
              let bdObj = this.formServiceDeliveryBreakdown.breakdowns[i];
              if (bdObj.staff !== null) {


                let breakdownDataToSubmit = {
                  serviceDeliveryBreakdownBreakdownsId: dataToSubmit.date,
                  serviceDeliveryBreakdownForStaffStaffId: bdObj.staff.id,

                  onsiteDays: bdObj.onsiteDays,
                  startOnTime: bdObj.startOnTime,
                  enoughTime: bdObj.enoughTime,
                  lifestyleChanges: bdObj.lifestyleChanges,
                  professionalism: bdObj.professionalism,
                  assessmentRating: bdObj.assessmentRating,
                  recommend: bdObj.recommend,

                };

                console.log('create (breakdown)', breakdownDataToSubmit);
                let createBreakdownResult = await API.graphql(graphqlOperation(createServiceDeliveryBreakdownForStaff, { input: breakdownDataToSubmit }))
                console.log('create (breakdown) -> result', createBreakdownResult);

              }
            }

            Swal.fire('Saved!', '', 'success')
            this.formServiceDeliveryBreakdownClear();
            this.loadServiceDeliveryBreakdownDbRows();
          }

          console.log('create -> complete');

        } else {

          console.log('update', existingResult);

          for (let i=0; i<this.formServiceDeliveryBreakdown.breakdowns.length; i++) {
            let bdObj = this.formServiceDeliveryBreakdown.breakdowns[i];
            if (bdObj.staff !== null) {

              let breakdownDataToSubmit = {
                serviceDeliveryBreakdownBreakdownsId: dataToSubmit.date,
                serviceDeliveryBreakdownForStaffStaffId: bdObj.staff.id,

                onsiteDays: bdObj.onsiteDays,
                startOnTime: bdObj.startOnTime,
                enoughTime: bdObj.enoughTime,
                lifestyleChanges: bdObj.lifestyleChanges,
                professionalism: bdObj.professionalism,
                assessmentRating: bdObj.assessmentRating,
                recommend: bdObj.recommend,
              };


              let breakdownUpdateDataToSubmit = {
                id: bdObj.id,
                ...breakdownDataToSubmit
              }

              if (bdObj.markedDeleted === true) {
                if (bdObj.id !== null) {
                  console.log('delete (breakdown)', breakdownUpdateDataToSubmit);
                  let deleteBreakdownResult = await API.graphql(graphqlOperation(deleteServiceDeliveryBreakdownForStaff, {input:{id: bdObj.id}}));
                  console.log('delete (breakdown) -> result', deleteBreakdownResult);
                }
              } else {
                if (bdObj.id !== null) {
                  console.log('update (breakdown)', breakdownUpdateDataToSubmit);
                  let updateBreakdownResult = await API.graphql(graphqlOperation(updateServiceDeliveryBreakdownForStaff, {input: breakdownUpdateDataToSubmit}))
                  console.log('update (breakdown) -> result', updateBreakdownResult);
                } else {
                  console.log('create (breakdown)', breakdownDataToSubmit);
                  let createBreakdownResult = await API.graphql(graphqlOperation(createServiceDeliveryBreakdownForStaff, {input: breakdownDataToSubmit}))
                  console.log('create (breakdown) -> result', createBreakdownResult);
                }
              }

            }
          }

          Swal.fire('Saved!', '', 'success')
          this.formServiceDeliveryBreakdownClear();
          this.loadServiceDeliveryBreakdownDbRows();

        }

      },

      formServiceDeliveryBreakdownAddBreakdown(staff = null) {
        this.formServiceDeliveryBreakdown.breakdowns.push({
          id: null,
          staff: staff,

          onsiteDays: 0,
          startOnTime: 0,
          enoughTime: 0,
          lifestyleChanges: 0,
          professionalism: 0,
          assessmentRating: 0,
          recommend: 0,

          markedDeleted: false,
        });
      },

      formServiceDeliveryBreakdownClear() {
        let today = new Date();
        let todayMonth = (today.getMonth() + 1);
        let todayYear = (today.getFullYear());

        this.formServiceDeliveryBreakdown = {
          dateMonth: todayMonth,
          dateYear: todayYear,
          breakdowns: []
        };

        for (let si=0; si<this.dbStaffRows.length; si++) {
          let staff = this.dbStaffRows[si];
          this.formServiceDeliveryBreakdownAddBreakdown(staff);
        }

      },

      formServiceDeliveryBreakdownFillFromRow(dbRow) {

        let dateSplit = dbRow.date.split("/");
        let breakdowns = [];

        for (let i=0; i<dbRow.breakdowns.items.length; i++) {
          let breakdownRow = dbRow.breakdowns.items[i];

          let localStaff = this.dbStaffById[breakdownRow.staff.id] || breakdownRow.staff

          breakdowns.push({
            id: breakdownRow.id,
            staff: localStaff,

            onsiteDays: breakdownRow.onsiteDays,
            startOnTime: breakdownRow.startOnTime,
            enoughTime: breakdownRow.enoughTime,
            lifestyleChanges: breakdownRow.lifestyleChanges,
            professionalism: breakdownRow.professionalism,
            assessmentRating: breakdownRow.assessmentRating,
            recommend: breakdownRow.recommend,

            markedDeleted: false,
          });
        }

        this.formServiceDeliveryBreakdown = {
          dateMonth: parseInt(dateSplit[0]),
          dateYear: parseInt(dateSplit[1]),
          breakdowns: breakdowns
        }

        this.scrollToElement("formServiceDeliveryBreakdown");

      },
    },

    computed: {

      dbServiceDeliveryBreakdownRowsSorted: function() {
        return [...this.dbServiceDeliveryBreakdownRows].sort((a, b) => {

          let dateASpl = a.date.split("/");
          let dateBSpl = b.date.split("/");

          let dateA = (new Date(parseInt(dateASpl[1]), parseInt(dateASpl[0]) - 1, 1));
          let dateB = (new Date(parseInt(dateBSpl[1]), parseInt(dateBSpl[0]) - 1, 1));

          return dateA > dateB ? 1 : -1
        });
      },

      dbStaffById: function() {
        let opts = {};
        for (let i=0; i<this.dbStaffRows.length; i++) {
          let staff = this.dbStaffRows[i];
          opts[staff.id] = staff;
        }
        return opts;
      },

      formStaffOptions: function() {
        let opts = [];
        for (let i=0; i<this.dbStaffRows.length; i++) {
          let staff = this.dbStaffRows[i];
          opts.push({
            value: staff,
            text: staff.name
          });
        }
        return opts;
      }

    },

    mounted() {

      this.loadStaffDbRows();

      this.formServiceDeliveryBreakdownClear();
      this.loadServiceDeliveryBreakdownDbRows();

    },

  }
</script>

<style scoped>

  th.rotate {
    height: 140px;
    white-space: nowrap;
    padding: 0 !important;
  }

  th.rotate > div {
    transform:
        translate(13px, 0px)
        rotate(310deg);
    width: 30px;
  }

  th.rotate > div > span {
    padding: 5px 10px;
  }

</style>
