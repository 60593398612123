<template>
  <!-- WRAPPED -->
  <div v-if="!noWrap" class="form-group row">
    <label :class="labelClass">{{ label }}</label>
    <div :class="inputWrapClass">
      <select
          ref="input"
          :class="inputClass"
          v-bind="$attrs"
          v-model="value"
      >
        <option v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</option>
      </select>
      <div v-if="error !== false" class="invalid-feedback">
        {{ error }}
      </div>
    </div>
  </div>
  <!-- NON WRAPPED -->
  <select
      v-else
      ref="input"
      :class="inputClass"
      v-bind="$attrs"
      v-model="value"
  >
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</option>
  </select>

</template>

<script>
  export default {
    name: "SelectInput",

    props: {

      // -- CORE

      modelValue: {
        type: [String, Object],
        default: null,
      },

      label: {
        type: String,
        default: ''
      },

      labelWidth: {
        type: Number,
        default: 3
      },

      inputWidth: {
        type: Number,
        default: 9
      },

      noWrap: {
        type: Boolean,
        default: false
      },

      error: {
        type: [ String, Boolean ],
        default: false
      },

      // -- SELECT

      selectOptions: {
        type: Array,
        default: function () {
          return []
        }
      },

      hasNull: {
        type: Boolean,
        default: true
      },

    },

    computed: {

      labelClass: function () {
        return {
          ['col-sm-' + this.labelWidth]: true,
          'col-form-label': true
        };
      },

      inputWrapClass: function () {
        return {
          ['col-sm-' + this.inputWidth]: true
        };
      },

      inputClass: function () {
        return {
          'form-control': true,
          'is-invalid': this.error !== false
        };
      },

      options: function () {
        if (!this.hasNull) {
          return this.selectOptions;
        }

        return [
            { value:null, text:'Please select' },
            ...this.selectOptions,
        ];
      },

      value: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        }
      }

    },

    emits: ['update:modelValue'],

    methods: {

    }

  }
</script>

<style scoped>

</style>