export const listSalesBreakdownsComplete = /* GraphQL */ `
  query ListSalesBreakdowns(
    $date: String
    $filter: ModelSalesBreakdownFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSalesBreakdowns(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        breakdowns {
          items {
            id
            sales
            pipeline
            staff {
              id
              name
              department
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCustomerBreakdownsComplete = /* GraphQL */ `
  query ListCustomerBreakdowns(
    $date: String
    $filter: ModelCustomerBreakdownFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerBreakdowns(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        breakdowns {
          items {
            id
            support
            communication
            positiveImpact
            overallPerformance
            valueForMoney
            staff {
              id
              name
              department
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listServiceDeliveryBreakdownsComplete = /* GraphQL */ `
  query ListServiceDeliveryBreakdowns(
    $date: String
    $filter: ModelServiceDeliveryBreakdownFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceDeliveryBreakdowns(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        breakdowns {
          items {
            id    
            onsiteDays
            startOnTime
            enoughTime
            lifestyleChanges
            professionalism
            assessmentRating
            recommend
            staff {
              id
              name
              department
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;