<template>
  <div>
    <h3>Sales</h3>

    <div class="outer mb-3">
      <div class="inner">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="fix" style="color: transparent;">.</th>
              <th v-if="dbSalesRowsSorted.length == 0">N/A</th>
              <th v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ dbRow.date }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="fix text-right">EHA</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ moneyFormat(dbRow.eha) }} / {{ moneyFormat(dbRow.ehaBudget) }}</td>
            </tr>
            <tr>
              <th class="fix text-right">MWC</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ moneyFormat(dbRow.mwc) }} / {{ moneyFormat(dbRow.mwcBudget) }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Pascal</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ moneyFormat(dbRow.pascal) }} / {{ moneyFormat(dbRow.pascalBudget) }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Healthy Workstation</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ moneyFormat(dbRow.healthyWorkstation) }} / {{ moneyFormat(dbRow.healthyWorkstationBudget) }}</td>
            </tr>
            <tr>
              <th class="fix text-right">DFV</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ moneyFormat(dbRow.dfv) }} / {{ moneyFormat(dbRow.dfvBudget) }}</td>
            </tr>
            <tr>
              <th class="fix text-right">OnDemand/Calendars</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ moneyFormat(dbRow.onDemandCalendars) }} / {{ moneyFormat(dbRow.onDemandCalendarsBudget) }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Workshops/Promotion</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ moneyFormat(dbRow.workshopsPromotion) }} / {{ moneyFormat(dbRow.workshopsPromotionBudget) }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Other</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">{{ moneyFormat(dbRow.other) }} / {{ moneyFormat(dbRow.otherBudget) }}</td>
            </tr>

            <tr>
              <th class="fix text-right" style="height: 55.5px;">Actions</th>
              <td v-if="dbSalesRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbSalesRowsSorted" :key="dbRow.date">
                <button type="button" class="btn btn-sm btn-block btn-outline-warning" @click="formSalesFillFromRow(dbRow)">Edit</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <year-month-input label="Date" v-model:modelMonthValue="formSales.dateMonth" v-model:modelYearValue="formSales.dateYear"></year-month-input>

        <div class="row">
          <div class="col-6">
            <number-input label="EHA" :labelWidth="6" :inputWidth="6" v-model="formSales.eha" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Budget (EHA)" :labelWidth="6" :inputWidth="6" v-model="formSales.ehaBudget" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="MWC" :labelWidth="6" :inputWidth="6" v-model="formSales.mwc" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Budget (MWC)" :labelWidth="6" :inputWidth="6" v-model="formSales.mwcBudget" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="Pascal" :labelWidth="6" :inputWidth="6" v-model="formSales.pascal" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Budget (Pascal)" :labelWidth="6" :inputWidth="6" v-model="formSales.pascalBudget" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="Healthy Workstation" :labelWidth="6" :inputWidth="6" v-model="formSales.healthyWorkstation" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Budget (Healthy Workstation)" :labelWidth="6" :inputWidth="6" v-model="formSales.healthyWorkstationBudget" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="DFV" :labelWidth="6" :inputWidth="6" v-model="formSales.dfv" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Budget (DFV)" :labelWidth="6" :inputWidth="6" v-model="formSales.dfvBudget" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="OnDemand/Calendars" :labelWidth="6" :inputWidth="6" v-model="formSales.onDemandCalendars" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Budget (OnDemand/Calendars)" :labelWidth="6" :inputWidth="6" v-model="formSales.onDemandCalendarsBudget" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="Workshops/Promotion" :labelWidth="6" :inputWidth="6" v-model="formSales.workshopsPromotion" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Budget (Workshops/Promotion)" :labelWidth="6" :inputWidth="6" v-model="formSales.workshopsPromotionBudget" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="Other" :labelWidth="6" :inputWidth="6" v-model="formSales.other" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Budget (Other)" :labelWidth="6" :inputWidth="6" v-model="formSales.otherBudget" min="-1"></number-input>
          </div>
        </div>
        
        <div class="form-group row mb-0">
          <div class="offset-sm-3 col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formSalesSubmit">Create / Update</button>
          </div>
          <div class="col-sm-4">
            <button class="btn btn-block btn-outline-warning" @click="formSalesClear">Clear</button>
          </div>
        </div>
      </div>
    </div>

    <h3>Sales Breakdown</h3>

    <div v-for="(dbRow, i) in dbSalesBreakdownRowsSorted" :key="i">
      <div class="card mb-3">
        <div class="card-header">
          {{ dbRow.date }}
          <button class="btn btn-outline-warning btn-sm float-right" @click="formSalesBreakdownFillFromRow(dbRow)">Edit</button>
        </div>
        <div class="card-body">

          <table class="table">
            <thead>
              <tr>
                <th>Staff</th>
                <th>Sales</th>
                <th>Pipeline</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, j) in dbRow.breakdowns.items" :key="j">
                <td>{{ item.staff.name }}</td>
                <td>{{ item.sales }}</td>
                <td>{{ item.pipeline }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card mb-3" ref="formSalesBreakdown">
      <div class="card-body">
        <year-month-input label="Date" v-model:modelMonthValue="formSalesBreakdown.dateMonth" v-model:modelYearValue="formSalesBreakdown.dateYear"></year-month-input>

        <div class="form-group row" min="-1">
          <label class="col-sm-3 col-form-label">Staff</label>
          <div class="col-sm-9">

            <table class="table">
              <thead>
                <tr>
                  <th>Staff</th>
                  <th>Sales</th>
                  <th>Pipeline</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in formSalesBreakdown.breakdowns" :key="i" :class="{'table-danger':item.markedDeleted}">
                  <td>
                    <select-input
                        label="a"
                        :selectOptions="formStaffOptions"
                        v-model="item.staff"
                        :noWrap="true"
                    ></select-input>
                  </td>
                  <td>
                    <number-input v-model="item.sales" min="-1" :noWrap="true"></number-input>
                  </td>
                  <td>
                    <number-input v-model="item.pipeline" min="-1" :noWrap="true"></number-input>
                  </td>
                  <td>
                    <button
                        :class="{'btn':true, 'btn-block':true, 'btn-danger':item.markedDeleted, 'btn-outline-danger':!item.markedDeleted}"
                        @click="item.markedDeleted = !item.markedDeleted"
                    >X</button>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <button class="btn btn-block btn-outline-warning" @click="formSalesBreakdownAddBreakdown">Add</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="form-group row mb-0">
          <div class="offset-sm-3 col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formSalesBreakdownSubmit">Create / Update</button>
          </div>
          <div class="col-sm-4">
            <button class="btn btn-block btn-outline-warning" @click="formSalesBreakdownClear">Clear</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import Swal from 'sweetalert2';

  import { API, graphqlOperation } from "aws-amplify";
  import {
    getSales,
    getSalesBreakdown,
    listSales,
    listStaff
  } from "@/graphql/queries";
  import { listSalesBreakdownsComplete } from "@/graphql/custom/queries";
  import {
    createSales,
    createSalesBreakdown,
    createSalesBreakdownForStaff,
    deleteSalesBreakdownForStaff,
    updateSales,
    updateSalesBreakdownForStaff
  } from "@/graphql/mutations";

  import NumberInput from "@/components/form/NumberInput";
  import YearMonthInput from "@/components/form/YearMonthInput";
  import SelectInput from "@/components/form/SelectInput";

  export default {
    name: 'SalesTab',

    components: {
      YearMonthInput,
      NumberInput,
      SelectInput
    },

    data() {
      return {

        formSales: {
          dateMonth: 1,
          dateYear: 2022,

          eha: 0,
          mwc: 0,
          pascal: 0,
          healthyWorkstation: 0,
          dfv: 0,
          onDemandCalendars: 0,
          workshopsPromotion: 0,
          other: 0,

          ehaBudget: 0,
          mwcBudget: 0,
          pascalBudget: 0,
          healthyWorkstationBudget: 0,
          dfvBudget: 0,
          onDemandCalendarsBudget: 0,
          workshopsPromotionBudget: 0,
          otherBudget: 0

        },

        formSalesBreakdown: {
          dateMonth: 1,
          dateYear: 2022,
          breakdowns: []
        },

        dbStaffRows: [],
        dbSalesRows: [],
        dbSalesBreakdownRows: [],

      }
    },

    methods: {

      scrollToElement(refName) {
        let element = this.$refs[refName];
        let top = element.offsetTop;

        window.scrollTo(0, top);
      },

      // ---

      loadStaffDbRows() {
        API.graphql(graphqlOperation(listStaff, {
          filter: {
            department: {eq: "sales"}
          }
        })).then((result) => {
          this.dbStaffRows = result.data.listStaff.items;
          this.formSalesBreakdownClear(); // Update it now we have staff.
        });
      },

      // ---

      loadSalesDbRows() {
        API.graphql(graphqlOperation(listSales)).then((result) => {
          this.dbSalesRows = result.data.listSales.items;
        });
      },

      formSalesSubmit() {

        const dataToSubmit = {
          date: this.formSales.dateMonth + "/" + this.formSales.dateYear,

          eha: this.formSales.eha,
          mwc: this.formSales.mwc,
          pascal: this.formSales.pascal,
          healthyWorkstation: this.formSales.healthyWorkstation,
          dfv: this.formSales.dfv,
          onDemandCalendars: this.formSales.onDemandCalendars,
          workshopsPromotion: this.formSales.workshopsPromotion,
          other: this.formSales.other,

          ehaBudget: this.formSales.ehaBudget,
          mwcBudget: this.formSales.mwcBudget,
          pascalBudget: this.formSales.pascalBudget,
          healthyWorkstationBudget: this.formSales.healthyWorkstationBudget,
          dfvBudget: this.formSales.dfvBudget,
          onDemandCalendarsBudget: this.formSales.onDemandCalendarsBudget,
          workshopsPromotionBudget: this.formSales.workshopsPromotionBudget,
          otherBudget: this.formSales.otherBudget

        };

        API.graphql(graphqlOperation(getSales, { date: dataToSubmit.date })).then((existingResult) => {
          if (existingResult.data.getSales === null) {
            console.log('create');
            API.graphql(graphqlOperation(createSales, { input: dataToSubmit })).then((createResult) => {
              console.log('create -> result', createResult);
              Swal.fire('Saved!', '', 'success')
              this.formSalesClear();
              this.loadSalesDbRows();
            });
          } else {
            console.log('update');
            Swal.fire({
              title: 'Error!',
              text: this.formSales.dateMonth + "/" + this.formSales.dateYear + ' has already been submitted, are you sure you wish to update it?',
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('update -> confirmed');
                API.graphql(graphqlOperation(updateSales, { input: dataToSubmit })).then((updateResult) => {
                  console.log('update -> result', updateResult);
                  Swal.fire('Saved!', '', 'success')
                  this.formSalesClear();
                  this.loadSalesDbRows();
                });
              }
            });
          }
        });

      },

      formSalesClear() {

        let today = new Date();
        this.formSales = {
          dateMonth: (today.getMonth() + 1),
          dateYear: (today.getFullYear()),

          eha: 0,
          mwc: 0,
          pascal: 0,
          healthyWorkstation: 0,
          dfv: 0,
          onDemandCalendars: 0,
          workshopsPromotion: 0,
          other: 0,

          ehaBudget: 0,
          mwcBudget: 0,
          pascalBudget: 0,
          healthyWorkstationBudget: 0,
          dfvBudget: 0,
          onDemandCalendarsBudget: 0,
          workshopsPromotionBudget: 0,
          otherBudget: 0

        };

      },

      formSalesFillFromRow(dbRow) {
        let dateSplit = dbRow.date.split("/");
        this.formSales = {
          dateMonth: parseInt(dateSplit[0]),
          dateYear: parseInt(dateSplit[1]),

          eha: dbRow.eha,
          mwc: dbRow.mwc,
          pascal: dbRow.pascal,
          healthyWorkstation: dbRow.healthyWorkstation,
          dfv: dbRow.dfv,
          onDemandCalendars: dbRow.onDemandCalendars,
          workshopsPromotion: dbRow.workshopsPromotion,
          other: dbRow.other,

          ehaBudget: dbRow.ehaBudget,
          mwcBudget: dbRow.mwcBudget,
          pascalBudget: dbRow.pascalBudget,
          healthyWorkstationBudget: dbRow.healthyWorkstationBudget,
          dfvBudget: dbRow.dfvBudget,
          onDemandCalendarsBudget: dbRow.onDemandCalendarsBudget,
          workshopsPromotionBudget: dbRow.workshopsPromotionBudget,
          otherBudget: dbRow.otherBudget
        };
      },

      // ---

      loadSalesBreakdownDbRows() {
        API.graphql(graphqlOperation(listSalesBreakdownsComplete)).then((result) => {
          this.dbSalesBreakdownRows = result.data.listSalesBreakdowns.items;
          this.formSalesBreakdownClear();
        });
      },

      async formSalesBreakdownSubmit() {

        const dataToSubmit = {
          date: this.formSalesBreakdown.dateMonth + "/" + this.formSalesBreakdown.dateYear,
        };
        const existingResult = await API.graphql(graphqlOperation(getSalesBreakdown, { date: dataToSubmit.date }));
        if (existingResult.data.getSalesBreakdown === null) {
          console.log('create', dataToSubmit);

          const createResult = await API.graphql(graphqlOperation(createSalesBreakdown, { input: dataToSubmit }));
          console.log('create -> result', createResult);

          if (createResult.data.createSalesBreakdown !== null) {
            for (let i=0; i<this.formSalesBreakdown.breakdowns.length; i++) {
              let bdObj = this.formSalesBreakdown.breakdowns[i];
              if (bdObj.staff !== null) {

                let breakdownDataToSubmit = {
                  salesBreakdownBreakdownsId: dataToSubmit.date,
                  salesBreakdownForStaffStaffId: bdObj.staff.id,
                  sales: bdObj.sales,
                  pipeline: bdObj.pipeline,
                };

                console.log('create (breakdown)', breakdownDataToSubmit);
                let createBreakdownResult = await API.graphql(graphqlOperation(createSalesBreakdownForStaff, { input: breakdownDataToSubmit }))
                console.log('create (breakdown) -> result', createBreakdownResult);

              }
            }

            Swal.fire('Saved!', '', 'success')
            this.formSalesBreakdownClear();
            this.loadSalesBreakdownDbRows();
          }

          console.log('create -> complete');

        } else {
          console.log('update', existingResult);

          for (let i=0; i<this.formSalesBreakdown.breakdowns.length; i++) {
            let bdObj = this.formSalesBreakdown.breakdowns[i];
            if (bdObj.staff !== null) {

              let breakdownDataToSubmit = {
                salesBreakdownBreakdownsId: dataToSubmit.date,
                salesBreakdownForStaffStaffId: bdObj.staff.id,
                sales: bdObj.sales,
                pipeline: bdObj.pipeline,
              };

              let breakdownUpdateDataToSubmit = {
                id: bdObj.id,
                ...breakdownDataToSubmit
              }

              if (bdObj.markedDeleted === true) {
                if (bdObj.id !== null) {
                  console.log('delete (breakdown)', breakdownUpdateDataToSubmit);
                  let deleteBreakdownResult = await API.graphql(graphqlOperation(deleteSalesBreakdownForStaff, {input:{id: bdObj.id}}));
                  console.log('delete (breakdown) -> result', deleteBreakdownResult);
                }
              } else {
                if (bdObj.id !== null) {
                  console.log('update (breakdown)', breakdownUpdateDataToSubmit);
                  let updateBreakdownResult = await API.graphql(graphqlOperation(updateSalesBreakdownForStaff, {input: breakdownUpdateDataToSubmit}))
                  console.log('update (breakdown) -> result', updateBreakdownResult);
                } else {
                  console.log('create (breakdown)', breakdownDataToSubmit);
                  let createBreakdownResult = await API.graphql(graphqlOperation(createSalesBreakdownForStaff, {input: breakdownDataToSubmit}))
                  console.log('create (breakdown) -> result', createBreakdownResult);
                }
              }
            }
          }

          Swal.fire('Saved!', '', 'success')
          this.formSalesBreakdownClear();
          this.loadSalesBreakdownDbRows();

        }

      },

      formSalesBreakdownAddBreakdown(staff = null) {
        this.formSalesBreakdown.breakdowns.push({
          id: null,
          staff: staff,
          sales: 0,
          pipeline: 0,
          markedDeleted: false,
        });
      },

      formSalesBreakdownClear() {
        let today = new Date();
        let todayMonth = (today.getMonth() + 1);
        let todayYear = (today.getFullYear());

        this.formSalesBreakdown = {
          dateMonth: todayMonth,
          dateYear: todayYear,
          breakdowns: []
        };
        for (let si=0; si<this.dbStaffRows.length; si++) {
          let staff = this.dbStaffRows[si];
          this.formSalesBreakdownAddBreakdown(staff);
        }

      },

      formSalesBreakdownFillFromRow(dbRow) {

        let dateSplit = dbRow.date.split("/");
        let breakdowns = [];

        for (let i=0; i<dbRow.breakdowns.items.length; i++) {
          let breakdownRow = dbRow.breakdowns.items[i];

          let localStaff = this.dbStaffById[breakdownRow.staff.id] || breakdownRow.staff

          breakdowns.push({
            id: breakdownRow.id,
            staff: localStaff,
            sales: breakdownRow.sales,
            pipeline: breakdownRow.pipeline,
            markedDeleted: false,
          });
        }

        this.formSalesBreakdown = {
          dateMonth: parseInt(dateSplit[0]),
          dateYear: parseInt(dateSplit[1]),
          breakdowns: breakdowns
        }

        this.scrollToElement("formSalesBreakdown");

      },

      // ---

      moneyFormat(money) {
        return (new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', maximumFractionDigits: 0 }).format(money));
      },
    },

    computed: {

      dbSalesRowsSorted: function() {
        return [...this.dbSalesRows].sort((a, b) => {

          let dateASpl = a.date.split("/");
          let dateBSpl = b.date.split("/");

          let dateA = (new Date(parseInt(dateASpl[1]), parseInt(dateASpl[0]) - 1, 1));
          let dateB = (new Date(parseInt(dateBSpl[1]), parseInt(dateBSpl[0]) - 1, 1));

          return dateA > dateB ? 1 : -1
        });
      },

      dbSalesBreakdownRowsSorted: function() {
        return [...this.dbSalesBreakdownRows].sort((a, b) => {

          let dateASpl = a.date.split("/");
          let dateBSpl = b.date.split("/");

          let dateA = (new Date(parseInt(dateASpl[1]), parseInt(dateASpl[0]) - 1, 1));
          let dateB = (new Date(parseInt(dateBSpl[1]), parseInt(dateBSpl[0]) - 1, 1));

          return dateA > dateB ? 1 : -1
        });
      },

      dbStaffById: function() {
        let opts = {};
        for (let i=0; i<this.dbStaffRows.length; i++) {
          let staff = this.dbStaffRows[i];
          opts[staff.id] = staff;
        }
        return opts;
      },

      formStaffOptions: function() {
        let opts = [];
        for (let i=0; i<this.dbStaffRows.length; i++) {
          let staff = this.dbStaffRows[i];
          opts.push({
            value: staff,
            text: staff.name
          });
        }
        return opts;
      }

    },

    mounted() {

      this.loadStaffDbRows();

      this.formSalesClear();
      this.loadSalesDbRows();

      this.formSalesBreakdownClear();
      this.loadSalesBreakdownDbRows();

    },

  }
</script>

<style scoped>
  .outer {
    position: relative;
    /*border: 1px solid #dee2e6;*/
  }
  .inner {
    overflow-x: scroll;
    overflow-y: visible;
    margin-left: 220px;
  }
  .fix {
    position: absolute;
    margin-left: -220px;
    margin-top: -1px;
    width: 220px;
    border-right: 1px solid #dee2e6;
  }
</style>
