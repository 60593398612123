/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $input: CreateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    createStaff(input: $input, condition: $condition) {
      id
      name
      department
      createdAt
      updatedAt
    }
  }
`;
export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      id
      name
      department
      createdAt
      updatedAt
    }
  }
`;
export const deleteStaff = /* GraphQL */ `
  mutation DeleteStaff(
    $input: DeleteStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    deleteStaff(input: $input, condition: $condition) {
      id
      name
      department
      createdAt
      updatedAt
    }
  }
`;
export const createFinancial = /* GraphQL */ `
  mutation CreateFinancial(
    $input: CreateFinancialInput!
    $condition: ModelFinancialConditionInput
  ) {
    createFinancial(input: $input, condition: $condition) {
      date
      revenue
      budget
      profitLoss
      balanceSheet
      ebitdaRunRate
      createdAt
      updatedAt
    }
  }
`;
export const updateFinancial = /* GraphQL */ `
  mutation UpdateFinancial(
    $input: UpdateFinancialInput!
    $condition: ModelFinancialConditionInput
  ) {
    updateFinancial(input: $input, condition: $condition) {
      date
      revenue
      budget
      profitLoss
      balanceSheet
      ebitdaRunRate
      createdAt
      updatedAt
    }
  }
`;
export const deleteFinancial = /* GraphQL */ `
  mutation DeleteFinancial(
    $input: DeleteFinancialInput!
    $condition: ModelFinancialConditionInput
  ) {
    deleteFinancial(input: $input, condition: $condition) {
      date
      revenue
      budget
      profitLoss
      balanceSheet
      ebitdaRunRate
      createdAt
      updatedAt
    }
  }
`;
export const createSales = /* GraphQL */ `
  mutation CreateSales(
    $input: CreateSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    createSales(input: $input, condition: $condition) {
      date
      eha
      ehaBudget
      mwc
      mwcBudget
      pascal
      pascalBudget
      healthyWorkstation
      healthyWorkstationBudget
      dfv
      dfvBudget
      onDemandCalendars
      onDemandCalendarsBudget
      workshopsPromotion
      workshopsPromotionBudget
      other
      otherBudget
      createdAt
      updatedAt
    }
  }
`;
export const updateSales = /* GraphQL */ `
  mutation UpdateSales(
    $input: UpdateSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    updateSales(input: $input, condition: $condition) {
      date
      eha
      ehaBudget
      mwc
      mwcBudget
      pascal
      pascalBudget
      healthyWorkstation
      healthyWorkstationBudget
      dfv
      dfvBudget
      onDemandCalendars
      onDemandCalendarsBudget
      workshopsPromotion
      workshopsPromotionBudget
      other
      otherBudget
      createdAt
      updatedAt
    }
  }
`;
export const deleteSales = /* GraphQL */ `
  mutation DeleteSales(
    $input: DeleteSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    deleteSales(input: $input, condition: $condition) {
      date
      eha
      ehaBudget
      mwc
      mwcBudget
      pascal
      pascalBudget
      healthyWorkstation
      healthyWorkstationBudget
      dfv
      dfvBudget
      onDemandCalendars
      onDemandCalendarsBudget
      workshopsPromotion
      workshopsPromotionBudget
      other
      otherBudget
      createdAt
      updatedAt
    }
  }
`;
export const createSalesBreakdown = /* GraphQL */ `
  mutation CreateSalesBreakdown(
    $input: CreateSalesBreakdownInput!
    $condition: ModelSalesBreakdownConditionInput
  ) {
    createSalesBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          sales
          pipeline
          createdAt
          updatedAt
          salesBreakdownBreakdownsId
          salesBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSalesBreakdown = /* GraphQL */ `
  mutation UpdateSalesBreakdown(
    $input: UpdateSalesBreakdownInput!
    $condition: ModelSalesBreakdownConditionInput
  ) {
    updateSalesBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          sales
          pipeline
          createdAt
          updatedAt
          salesBreakdownBreakdownsId
          salesBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSalesBreakdown = /* GraphQL */ `
  mutation DeleteSalesBreakdown(
    $input: DeleteSalesBreakdownInput!
    $condition: ModelSalesBreakdownConditionInput
  ) {
    deleteSalesBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          sales
          pipeline
          createdAt
          updatedAt
          salesBreakdownBreakdownsId
          salesBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSalesBreakdownForStaff = /* GraphQL */ `
  mutation CreateSalesBreakdownForStaff(
    $input: CreateSalesBreakdownForStaffInput!
    $condition: ModelSalesBreakdownForStaffConditionInput
  ) {
    createSalesBreakdownForStaff(input: $input, condition: $condition) {
      id
      salesBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      sales
      pipeline
      createdAt
      updatedAt
      salesBreakdownBreakdownsId
      salesBreakdownForStaffStaffId
    }
  }
`;
export const updateSalesBreakdownForStaff = /* GraphQL */ `
  mutation UpdateSalesBreakdownForStaff(
    $input: UpdateSalesBreakdownForStaffInput!
    $condition: ModelSalesBreakdownForStaffConditionInput
  ) {
    updateSalesBreakdownForStaff(input: $input, condition: $condition) {
      id
      salesBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      sales
      pipeline
      createdAt
      updatedAt
      salesBreakdownBreakdownsId
      salesBreakdownForStaffStaffId
    }
  }
`;
export const deleteSalesBreakdownForStaff = /* GraphQL */ `
  mutation DeleteSalesBreakdownForStaff(
    $input: DeleteSalesBreakdownForStaffInput!
    $condition: ModelSalesBreakdownForStaffConditionInput
  ) {
    deleteSalesBreakdownForStaff(input: $input, condition: $condition) {
      id
      salesBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      sales
      pipeline
      createdAt
      updatedAt
      salesBreakdownBreakdownsId
      salesBreakdownForStaffStaffId
    }
  }
`;
export const createMarketing = /* GraphQL */ `
  mutation CreateMarketing(
    $input: CreateMarketingInput!
    $condition: ModelMarketingConditionInput
  ) {
    createMarketing(input: $input, condition: $condition) {
      date
      websiteEnquiries
      campaignEnquiries
      socialMediaFollowers
      createdAt
      updatedAt
    }
  }
`;
export const updateMarketing = /* GraphQL */ `
  mutation UpdateMarketing(
    $input: UpdateMarketingInput!
    $condition: ModelMarketingConditionInput
  ) {
    updateMarketing(input: $input, condition: $condition) {
      date
      websiteEnquiries
      campaignEnquiries
      socialMediaFollowers
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarketing = /* GraphQL */ `
  mutation DeleteMarketing(
    $input: DeleteMarketingInput!
    $condition: ModelMarketingConditionInput
  ) {
    deleteMarketing(input: $input, condition: $condition) {
      date
      websiteEnquiries
      campaignEnquiries
      socialMediaFollowers
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerBreakdown = /* GraphQL */ `
  mutation CreateCustomerBreakdown(
    $input: CreateCustomerBreakdownInput!
    $condition: ModelCustomerBreakdownConditionInput
  ) {
    createCustomerBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          support
          communication
          positiveImpact
          overallPerformance
          valueForMoney
          createdAt
          updatedAt
          customerBreakdownBreakdownsId
          customerBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerBreakdown = /* GraphQL */ `
  mutation UpdateCustomerBreakdown(
    $input: UpdateCustomerBreakdownInput!
    $condition: ModelCustomerBreakdownConditionInput
  ) {
    updateCustomerBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          support
          communication
          positiveImpact
          overallPerformance
          valueForMoney
          createdAt
          updatedAt
          customerBreakdownBreakdownsId
          customerBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerBreakdown = /* GraphQL */ `
  mutation DeleteCustomerBreakdown(
    $input: DeleteCustomerBreakdownInput!
    $condition: ModelCustomerBreakdownConditionInput
  ) {
    deleteCustomerBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          support
          communication
          positiveImpact
          overallPerformance
          valueForMoney
          createdAt
          updatedAt
          customerBreakdownBreakdownsId
          customerBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerBreakdownForStaff = /* GraphQL */ `
  mutation CreateCustomerBreakdownForStaff(
    $input: CreateCustomerBreakdownForStaffInput!
    $condition: ModelCustomerBreakdownForStaffConditionInput
  ) {
    createCustomerBreakdownForStaff(input: $input, condition: $condition) {
      id
      customerBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      support
      communication
      positiveImpact
      overallPerformance
      valueForMoney
      createdAt
      updatedAt
      customerBreakdownBreakdownsId
      customerBreakdownForStaffStaffId
    }
  }
`;
export const updateCustomerBreakdownForStaff = /* GraphQL */ `
  mutation UpdateCustomerBreakdownForStaff(
    $input: UpdateCustomerBreakdownForStaffInput!
    $condition: ModelCustomerBreakdownForStaffConditionInput
  ) {
    updateCustomerBreakdownForStaff(input: $input, condition: $condition) {
      id
      customerBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      support
      communication
      positiveImpact
      overallPerformance
      valueForMoney
      createdAt
      updatedAt
      customerBreakdownBreakdownsId
      customerBreakdownForStaffStaffId
    }
  }
`;
export const deleteCustomerBreakdownForStaff = /* GraphQL */ `
  mutation DeleteCustomerBreakdownForStaff(
    $input: DeleteCustomerBreakdownForStaffInput!
    $condition: ModelCustomerBreakdownForStaffConditionInput
  ) {
    deleteCustomerBreakdownForStaff(input: $input, condition: $condition) {
      id
      customerBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      support
      communication
      positiveImpact
      overallPerformance
      valueForMoney
      createdAt
      updatedAt
      customerBreakdownBreakdownsId
      customerBreakdownForStaffStaffId
    }
  }
`;
export const createServiceDeliveryBreakdown = /* GraphQL */ `
  mutation CreateServiceDeliveryBreakdown(
    $input: CreateServiceDeliveryBreakdownInput!
    $condition: ModelServiceDeliveryBreakdownConditionInput
  ) {
    createServiceDeliveryBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          onsiteDays
          startOnTime
          enoughTime
          lifestyleChanges
          professionalism
          assessmentRating
          recommend
          createdAt
          updatedAt
          serviceDeliveryBreakdownBreakdownsId
          serviceDeliveryBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateServiceDeliveryBreakdown = /* GraphQL */ `
  mutation UpdateServiceDeliveryBreakdown(
    $input: UpdateServiceDeliveryBreakdownInput!
    $condition: ModelServiceDeliveryBreakdownConditionInput
  ) {
    updateServiceDeliveryBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          onsiteDays
          startOnTime
          enoughTime
          lifestyleChanges
          professionalism
          assessmentRating
          recommend
          createdAt
          updatedAt
          serviceDeliveryBreakdownBreakdownsId
          serviceDeliveryBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteServiceDeliveryBreakdown = /* GraphQL */ `
  mutation DeleteServiceDeliveryBreakdown(
    $input: DeleteServiceDeliveryBreakdownInput!
    $condition: ModelServiceDeliveryBreakdownConditionInput
  ) {
    deleteServiceDeliveryBreakdown(input: $input, condition: $condition) {
      date
      breakdowns {
        items {
          id
          onsiteDays
          startOnTime
          enoughTime
          lifestyleChanges
          professionalism
          assessmentRating
          recommend
          createdAt
          updatedAt
          serviceDeliveryBreakdownBreakdownsId
          serviceDeliveryBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createServiceDeliveryBreakdownForStaff = /* GraphQL */ `
  mutation CreateServiceDeliveryBreakdownForStaff(
    $input: CreateServiceDeliveryBreakdownForStaffInput!
    $condition: ModelServiceDeliveryBreakdownForStaffConditionInput
  ) {
    createServiceDeliveryBreakdownForStaff(
      input: $input
      condition: $condition
    ) {
      id
      serviceDeliveryBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      onsiteDays
      startOnTime
      enoughTime
      lifestyleChanges
      professionalism
      assessmentRating
      recommend
      createdAt
      updatedAt
      serviceDeliveryBreakdownBreakdownsId
      serviceDeliveryBreakdownForStaffStaffId
    }
  }
`;
export const updateServiceDeliveryBreakdownForStaff = /* GraphQL */ `
  mutation UpdateServiceDeliveryBreakdownForStaff(
    $input: UpdateServiceDeliveryBreakdownForStaffInput!
    $condition: ModelServiceDeliveryBreakdownForStaffConditionInput
  ) {
    updateServiceDeliveryBreakdownForStaff(
      input: $input
      condition: $condition
    ) {
      id
      serviceDeliveryBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      onsiteDays
      startOnTime
      enoughTime
      lifestyleChanges
      professionalism
      assessmentRating
      recommend
      createdAt
      updatedAt
      serviceDeliveryBreakdownBreakdownsId
      serviceDeliveryBreakdownForStaffStaffId
    }
  }
`;
export const deleteServiceDeliveryBreakdownForStaff = /* GraphQL */ `
  mutation DeleteServiceDeliveryBreakdownForStaff(
    $input: DeleteServiceDeliveryBreakdownForStaffInput!
    $condition: ModelServiceDeliveryBreakdownForStaffConditionInput
  ) {
    deleteServiceDeliveryBreakdownForStaff(
      input: $input
      condition: $condition
    ) {
      id
      serviceDeliveryBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      onsiteDays
      startOnTime
      enoughTime
      lifestyleChanges
      professionalism
      assessmentRating
      recommend
      createdAt
      updatedAt
      serviceDeliveryBreakdownBreakdownsId
      serviceDeliveryBreakdownForStaffStaffId
    }
  }
`;
export const createIso = /* GraphQL */ `
  mutation CreateIso(
    $input: CreateIsoInput!
    $condition: ModelIsoConditionInput
  ) {
    createIso(input: $input, condition: $condition) {
      date
      clientComplaints
      improvementLogOpen
      auditsCompleted
      nonConformityOpen
      carsRaised
      supplierIssuesRaised
      createdAt
      updatedAt
    }
  }
`;
export const updateIso = /* GraphQL */ `
  mutation UpdateIso(
    $input: UpdateIsoInput!
    $condition: ModelIsoConditionInput
  ) {
    updateIso(input: $input, condition: $condition) {
      date
      clientComplaints
      improvementLogOpen
      auditsCompleted
      nonConformityOpen
      carsRaised
      supplierIssuesRaised
      createdAt
      updatedAt
    }
  }
`;
export const deleteIso = /* GraphQL */ `
  mutation DeleteIso(
    $input: DeleteIsoInput!
    $condition: ModelIsoConditionInput
  ) {
    deleteIso(input: $input, condition: $condition) {
      date
      clientComplaints
      improvementLogOpen
      auditsCompleted
      nonConformityOpen
      carsRaised
      supplierIssuesRaised
      createdAt
      updatedAt
    }
  }
`;
