<template>
  <!-- WRAPPED -->
  <div v-if="!noWrap" class="form-group row">
    <label :class="labelClass">{{ label }}</label>
    <div :class="inputWrapClass">
      <input
          ref="input"
          type="number"
          class="form-control"
          v-bind="$attrs"
          :value="modelValue"
          @input="updateValue($event.target.value)"
      >
    </div>
  </div>
  <!-- NON WRAPPED -->
  <input
      v-else
      ref="input"
      type="number"
      class="form-control"
      v-bind="$attrs"
      :value="modelValue"
      @input="updateValue($event.target.value)"
  >
</template>

<script>
  export default {
    name: "NumberInput",

    props: {

      // -- CORE

      modelValue: {
        type: Number,
        default: null,
        validator: (p) => {
          return ['number'].indexOf(typeof p) !== -1 || p === null;
        },
      },

      label: {
        type: String,
        default: ''
      },

      labelWidth: {
        type: Number,
        default: 3
      },

      inputWidth: {
        type: Number,
        default: 9
      },

      noWrap: {
        type: Boolean,
        default: false
      },

      /*
      error: {
        type: [ String, Boolean ],
        default: false
      },
      */

      // -- NUMBER

    },

    computed: {

      labelClass: function () {
        return {
          ['col-sm-' + this.labelWidth]: true,
          'col-form-label': true
        };
      },

      inputWrapClass: function () {
        return {
          ['col-sm-' + this.inputWidth]: true
        };
      }

    },

    methods: {

      updateValue: function (value) {
        //console.log('updateValue', value);
        if (value.length === 0) {
          this.$emit('update:modelValue', 0);
          this.$refs.input.value = '0';
        } else {
          this.$emit('update:modelValue', parseInt(value));
        }
      }

    }

  }
</script>

<style scoped>

</style>