<template>
  <div>
    <h3>Marketing</h3>

    <div class="outer mb-3">
      <div class="inner">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="fix" style="color: transparent;">.</th>
              <th v-if="dbRowsSorted.length == 0">N/A</th>
              <th v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.date }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="fix text-right">Website Enquiries</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.websiteEnquiries }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Campaign Enquiries</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.campaignEnquiries }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Social Media Enquiries</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.socialMediaFollowers }}</td>
            </tr>
            <tr>
              <th class="fix text-right" style="height: 55.5px;">Actions</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">
                <button type="button" class="btn btn-sm btn-block btn-outline-warning" @click="formFillFromRow(dbRow)">Edit</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <year-month-input label="Date" v-model:modelMonthValue="form.dateMonth" v-model:modelYearValue="form.dateYear"></year-month-input>

        <div class="row">
          <div class="col-6">
            <number-input label="Website Enquiries" :labelWidth="6" :inputWidth="6" v-model="form.websiteEnquiries" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Campaign Enquiries" :labelWidth="6" :inputWidth="6" v-model="form.campaignEnquiries" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="Social Media Enquiries" :labelWidth="6" :inputWidth="6" v-model="form.socialMediaFollowers" min="-1"></number-input>
          </div>
        </div>

        <div class="form-group row mb-0">
          <div class="offset-sm-3 col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formSubmit">Create / Update</button>
          </div>
          <div class="col-sm-4">
            <button class="btn btn-block btn-outline-warning" @click="formClear">Clear</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

  import Swal from 'sweetalert2';

  import { API, graphqlOperation } from "aws-amplify";
  import { getMarketing, listMarketings } from "@/graphql/queries";
  import { createMarketing, updateMarketing } from "@/graphql/mutations";

  import NumberInput from "@/components/form/NumberInput";
  import YearMonthInput from "@/components/form/YearMonthInput";

  export default {
    name: "MarketingTab",

    components: {
      YearMonthInput,
      NumberInput
    },

    data() {
      return {

        form: {
          dateMonth: 1,
          dateYear: 2022,
          websiteEnquiries: 0,
          campaignEnquiries: 0,
          socialMediaFollowers: 0
        },

        dbRows: [],
      };
    },

    methods: {

      loadDbRows() {
        API.graphql(graphqlOperation(listMarketings)).then((result) => {
          this.dbRows = result.data.listMarketings.items;
        });
      },

      formSubmit() {

        const dataToSubmit = {
          date: this.form.dateMonth + "/" + this.form.dateYear,
          websiteEnquiries: this.form.websiteEnquiries,
          campaignEnquiries: this.form.campaignEnquiries,
          socialMediaFollowers: this.form.socialMediaFollowers
        };


        API.graphql(graphqlOperation(getMarketing, { date: dataToSubmit.date })).then((existingResult) => {
          if (existingResult.data.getMarketing === null) {

            console.log('create');
            API.graphql(graphqlOperation(createMarketing, { input: dataToSubmit })).then((createResult) => {
              console.log('create -> result', createResult);
              Swal.fire('Saved!', '', 'success')
              this.formClear();
              this.loadDbRows();
            });
          } else {
            console.log('update');
            Swal.fire({
              title: 'Error!',
              text: this.form.dateMonth + "/" + this.form.dateYear + ' has already been submitted, are you sure you wish to update it?',
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('update -> confirmed');
                API.graphql(graphqlOperation(updateMarketing, { input: dataToSubmit })).then((updateResult) => {
                  console.log('update -> result', updateResult);
                  Swal.fire('Saved!', '', 'success')
                  this.formClear();
                  this.loadDbRows();
                });

              }
            });
          }
        });

      },

      formClear() {
        let today = new Date();
        this.form = {
          dateMonth: (today.getMonth() + 1),
          dateYear: (today.getFullYear()),
          websiteEnquiries: 0,
          campaignEnquiries: 0,
          socialMediaFollowers: 0
        };
      },

      formFillFromRow(dbRow) {
        let dateSplit = dbRow.date.split("/");
        this.form = {
          dateMonth: parseInt(dateSplit[0]),
          dateYear: parseInt(dateSplit[1]),
          websiteEnquiries: dbRow.websiteEnquiries,
          campaignEnquiries: dbRow.campaignEnquiries,
          socialMediaFollowers: dbRow.socialMediaFollowers
        };
      }

    },

    computed: {
      dbRowsSorted: function() {
        return [...this.dbRows].sort((a, b) => {

          let dateASpl = a.date.split("/");
          let dateBSpl = b.date.split("/");

          let dateA = (new Date(parseInt(dateASpl[1]), parseInt(dateASpl[0]) - 1, 1));
          let dateB = (new Date(parseInt(dateBSpl[1]), parseInt(dateBSpl[0]) - 1, 1));

          return dateA > dateB ? 1 : -1
        });
      }
    },

    mounted() {
      this.formClear();
      this.loadDbRows();
    },
  };
</script>

<style scoped>
  .outer {
    position: relative;
    /*border: 1px solid #dee2e6;*/
  }
  .inner {
    overflow-x: scroll;
    overflow-y: visible;
    margin-left: 240px;
  }
  .fix {
    position: absolute;
    margin-left: -240px;
    margin-top: -1px;
    width: 240px;
    border-right: 1px solid #dee2e6;
  }
</style>

