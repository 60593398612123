<template>
  <!-- WRAPPED -->
  <div v-if="!noWrap" class="form-group row">
    <label :class="labelClass">{{ label }}</label>
    <div :class="inputWrapClass">
      <input
          ref="input"
          :class="inputClass"
          v-bind="$attrs"
          :value="modelValue"
          @input="updateValue($event.target.value)"
      >
      <div v-if="error !== false" class="invalid-feedback">
        {{ error }}
      </div>
    </div>
  </div>
  <!-- NON WRAPPED -->
  <input
      v-else
      ref="input"
      :class="inputClass"
      v-bind="$attrs"
      :value="modelValue"
      @input="updateValue($event.target.value)"
  >
</template>

<script>
  export default {
    name: "TextInput",

    props: {


      // -- CORE

      modelValue: {
        type: String,
      },

      label: {
        type: String,
        default: ''
      },

      labelWidth: {
        type: Number,
        default: 3
      },

      inputWidth: {
        type: Number,
        default: 9
      },

      noWrap: {
        type: Boolean,
        default: false
      },

      error: {
        type: [ String, Boolean ],
        default: false
      }

      // -- TEXT

    },

    computed: {

      labelClass: function () {
        return {
          ['col-sm-' + this.labelWidth]: true,
          'col-form-label': true
        };
      },

      inputWrapClass: function () {
        return {
          ['col-sm-' + this.inputWidth]: true
        };
      },

      inputClass: function () {
        return {
          'form-control': true,
          'is-invalid': this.error !== false
        };
      }

    },

    methods: {

      updateValue: function (value) {
        this.$emit('update:modelValue', value);
      }

    }

  }
</script>

<style scoped>

</style>