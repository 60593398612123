<template>
  <div class="form-group row">
    <label :class="labelClass">Date</label>
    <div :class="inputMonthWrapClass">
      <select
          ref="inputMonth"
          class="form-control"
          :value="modelMonthValue"
          @input="updateMonthValue($event.target.value)"
      >
        <option :value="1">January</option>
        <option :value="2">February</option>
        <option :value="3">March</option>
        <option :value="4">April</option>
        <option :value="5">May</option>
        <option :value="6">June</option>
        <option :value="7">July</option>
        <option :value="8">August</option>
        <option :value="9">September</option>
        <option :value="10">October</option>
        <option :value="11">November</option>
        <option :value="12">December</option>
      </select>
    </div>
    <div :class="inputYearWrapClass">
      <input
          ref="inputYear"
          type="number"
          class="form-control"
          :min="inputYearMin"
          placeholder="Year"
          :value="modelYearValue"
          @input="updateYearValue($event.target.value)"
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: "YearMonthInput",

    props: {

      modelYearValue: {
        type: Number,
        default: null,
        validator: (p) => {
          return ['number'].indexOf(typeof p) !== -1 || p === null;
        },
      },

      modelMonthValue: {
        type: Number,
        default: null,
        validator: (p) => {
          return ['number'].indexOf(typeof p) !== -1 || p === null;
        },
      },

      label: {
        type: String,
        required: true
      },

      labelWidth: {
        type: Number,
        default: 3
      },

      inputMonthWidth: {
        type: Number,
        default: 5
      },

      inputYearWidth: {
        type: Number,
        default: 4
      },

      inputYearMin: {
        type: Number,
        default: 2010
      },

    },

    computed: {

      labelClass: function () {
        return {
          ['col-sm-' + this.labelWidth]: true,
          'col-form-label': true
        };
      },

      inputMonthWrapClass: function () {
        return {
          ['col-sm-' + this.inputMonthWidth]: true
        };
      },

      inputYearWrapClass: function () {
        return {
          ['col-sm-' + this.inputYearWidth]: true
        };
      }

    },

    methods: {

      updateYearValue: function (value) {

        let safeValue = 0;
        if (value.length > 0) {
          safeValue = parseInt(value);
        }

        if (safeValue < this.inputYearMin) {
          safeValue = this.inputYearMin;
          this.$refs.inputYear.value = '' + safeValue;
        }

        this.$emit('update:modelYearValue', safeValue);
      },

      updateMonthValue: function (value) {
        this.$emit('update:modelMonthValue', parseInt(value));
      }

    }

  }
</script>

<style scoped>

</style>