<template>
  <div>
    <h3>Financial</h3>

    <div class="outer mb-3">
      <div class="inner">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="fix" style="color: transparent;">.</th>
              <th v-if="dbRowsSorted.length == 0">N/A</th>
              <th v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.date }}</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <th class="fix text-right">Revenue</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.revenue }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Budget</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.budget }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Profit/Loss</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.profitLoss }}</td>
            </tr>
            <tr>
              <th class="fix text-right">Balance Sheet</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.balanceSheet }}</td>
            </tr>
            <tr>
              <th class="fix text-right">EBITDA Run Rate</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">{{ dbRow.ebitdaRunRate }}</td>
            </tr>
            <tr>
              <th class="fix text-right" style="height: 55.5px;">Actions</th>
              <td v-if="dbRowsSorted.length == 0">N/A</td>
              <td v-for="dbRow in dbRowsSorted" :key="dbRow.date">
                <button type="button" class="btn btn-sm btn-block btn-outline-warning" @click="formFillFromRow(dbRow)">Edit</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <year-month-input label="Date" v-model:modelMonthValue="form.dateMonth" v-model:modelYearValue="form.dateYear"></year-month-input>

        <div class="row">
          <div class="col-6">
            <number-input label="Revenue" :labelWidth="6" :inputWidth="6" v-model="form.revenue" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="Balance Sheet" :labelWidth="6" :inputWidth="6" v-model="form.balanceSheet" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="Budget" :labelWidth="6" :inputWidth="6" v-model="form.budget" min="-1"></number-input>
          </div>
          <div class="col-6">
            <number-input label="EBITDA Run Rate" :labelWidth="6" :inputWidth="6" v-model="form.ebitdaRunRate" min="-1"></number-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <number-input label="Profit/Loss" :labelWidth="6" :inputWidth="6" v-model="form.profitLoss" min="-1"></number-input>
          </div>
        </div>

        <div class="form-group row mb-0">
          <div class="offset-sm-3 col-sm-5">
            <button class="btn btn-block btn-outline-warning" @click="formSubmit">Create / Update</button>
          </div>
          <div class="col-sm-4">
            <button class="btn btn-block btn-outline-warning" @click="formClear">Clear</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import Swal from 'sweetalert2';

  import { API, graphqlOperation } from "aws-amplify";
  import { getFinancial, listFinancials } from "@/graphql/queries";
  import { createFinancial, updateFinancial } from "@/graphql/mutations";

  import NumberInput from "@/components/form/NumberInput";
  import YearMonthInput from "@/components/form/YearMonthInput";

  export default {
    name: "FinancialTab",

    components: {
      YearMonthInput,
      NumberInput
    },

    data() {
      return {

        form: {
          dateMonth: 1,
          dateYear: 2022,
          revenue: 0,
          budget: 0,
          profitLoss: 0,
          balanceSheet: 0,
          ebitdaRunRate: 0
        },

        dbRows: [],
      };
    },

    methods: {

      loadDbRows() {
        API.graphql(graphqlOperation(listFinancials)).then((result) => {
          this.dbRows = result.data.listFinancials.items;
        });
      },

      formSubmit() {

        const dataToSubmit = {
          date: this.form.dateMonth + "/" + this.form.dateYear,
          revenue: this.form.revenue,
          budget: this.form.budget,
          profitLoss: this.form.profitLoss,
          balanceSheet: this.form.balanceSheet,
          ebitdaRunRate: this.form.ebitdaRunRate
        };

        API.graphql(graphqlOperation(getFinancial, { date: dataToSubmit.date })).then((existingResult) => {
          if (existingResult.data.getFinancial === null) {

            console.log('create');
            API.graphql(graphqlOperation(createFinancial, { input: dataToSubmit })).then((createResult) => {
              console.log('create -> result', createResult);
              Swal.fire('Saved!', '', 'success')
              this.formClear();
              this.loadDbRows();
            });
          } else {
            console.log('update');
            Swal.fire({
              title: 'Error!',
              text: this.form.dateMonth + "/" + this.form.dateYear + ' has already been submitted, are you sure you wish to update it?',
              icon: 'error',
              showCancelButton: true,
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('update -> confirmed');
                API.graphql(graphqlOperation(updateFinancial, { input: dataToSubmit })).then((updateResult) => {
                  console.log('update -> result', updateResult);
                  Swal.fire('Saved!', '', 'success')
                  this.formClear();
                  this.loadDbRows();
                });

              }
            });
          }
        });
      },

      formClear() {
        let today = new Date();
        this.form = {
          dateMonth: (today.getMonth() + 1),
          dateYear: (today.getFullYear()),
          revenue: 0,
          budget: 0,
          profitLoss: 0,
          balanceSheet: 0,
          ebitdaRunRate: 0
        };
      },

      formFillFromRow(dbRow) {
        let dateSplit = dbRow.date.split("/");
        this.form = {
          dateMonth: parseInt(dateSplit[0]),
          dateYear: parseInt(dateSplit[1]),
          revenue: dbRow.revenue,
          budget: dbRow.budget,
          profitLoss: dbRow.profitLoss,
          balanceSheet: dbRow.balanceSheet,
          ebitdaRunRate: dbRow.ebitdaRunRate
        };
      }

    },

    computed: {
      dbRowsSorted: function() {
        return [...this.dbRows].sort((a, b) => {

          let dateASpl = a.date.split("/");
          let dateBSpl = b.date.split("/");

          let dateA = (new Date(parseInt(dateASpl[1]), parseInt(dateASpl[0]) - 1, 1));
          let dateB = (new Date(parseInt(dateBSpl[1]), parseInt(dateBSpl[0]) - 1, 1));

          return dateA > dateB ? 1 : -1
        });
      }
    },

    mounted() {
      this.formClear();
      this.loadDbRows();
    },
  };
</script>

<style scoped>
  .outer {
    position: relative;
    /*border: 1px solid #dee2e6;*/
  }
  .inner {
    overflow-x: scroll;
    overflow-y: visible;
    margin-left: 220px;
  }
  .fix {
    position: absolute;
    margin-left: -220px;
    margin-top: -1px;
    width: 220px;
    border-right: 1px solid #dee2e6;
  }
</style>
