/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      name
      department
      createdAt
      updatedAt
    }
  }
`;
export const listStaff = /* GraphQL */ `
  query ListStaff(
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaff(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        department
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFinancial = /* GraphQL */ `
  query GetFinancial($date: String!) {
    getFinancial(date: $date) {
      date
      revenue
      budget
      profitLoss
      balanceSheet
      ebitdaRunRate
      createdAt
      updatedAt
    }
  }
`;
export const listFinancials = /* GraphQL */ `
  query ListFinancials(
    $date: String
    $filter: ModelFinancialFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFinancials(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        revenue
        budget
        profitLoss
        balanceSheet
        ebitdaRunRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSales = /* GraphQL */ `
  query GetSales($date: String!) {
    getSales(date: $date) {
      date
      eha
      ehaBudget
      mwc
      mwcBudget
      pascal
      pascalBudget
      healthyWorkstation
      healthyWorkstationBudget
      dfv
      dfvBudget
      onDemandCalendars
      onDemandCalendarsBudget
      workshopsPromotion
      workshopsPromotionBudget
      other
      otherBudget
      createdAt
      updatedAt
    }
  }
`;
export const listSales = /* GraphQL */ `
  query ListSales(
    $date: String
    $filter: ModelSalesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSales(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        eha
        ehaBudget
        mwc
        mwcBudget
        pascal
        pascalBudget
        healthyWorkstation
        healthyWorkstationBudget
        dfv
        dfvBudget
        onDemandCalendars
        onDemandCalendarsBudget
        workshopsPromotion
        workshopsPromotionBudget
        other
        otherBudget
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSalesBreakdown = /* GraphQL */ `
  query GetSalesBreakdown($date: String!) {
    getSalesBreakdown(date: $date) {
      date
      breakdowns {
        items {
          id
          sales
          pipeline
          createdAt
          updatedAt
          salesBreakdownBreakdownsId
          salesBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSalesBreakdowns = /* GraphQL */ `
  query ListSalesBreakdowns(
    $date: String
    $filter: ModelSalesBreakdownFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSalesBreakdowns(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSalesBreakdownForStaff = /* GraphQL */ `
  query GetSalesBreakdownForStaff($id: ID!) {
    getSalesBreakdownForStaff(id: $id) {
      id
      salesBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      sales
      pipeline
      createdAt
      updatedAt
      salesBreakdownBreakdownsId
      salesBreakdownForStaffStaffId
    }
  }
`;
export const listSalesBreakdownForStaffs = /* GraphQL */ `
  query ListSalesBreakdownForStaffs(
    $filter: ModelSalesBreakdownForStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesBreakdownForStaffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        salesBreakdown {
          date
          createdAt
          updatedAt
        }
        staff {
          id
          name
          department
          createdAt
          updatedAt
        }
        sales
        pipeline
        createdAt
        updatedAt
        salesBreakdownBreakdownsId
        salesBreakdownForStaffStaffId
      }
      nextToken
    }
  }
`;
export const getMarketing = /* GraphQL */ `
  query GetMarketing($date: String!) {
    getMarketing(date: $date) {
      date
      websiteEnquiries
      campaignEnquiries
      socialMediaFollowers
      createdAt
      updatedAt
    }
  }
`;
export const listMarketings = /* GraphQL */ `
  query ListMarketings(
    $date: String
    $filter: ModelMarketingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMarketings(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        websiteEnquiries
        campaignEnquiries
        socialMediaFollowers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerBreakdown = /* GraphQL */ `
  query GetCustomerBreakdown($date: String!) {
    getCustomerBreakdown(date: $date) {
      date
      breakdowns {
        items {
          id
          support
          communication
          positiveImpact
          overallPerformance
          valueForMoney
          createdAt
          updatedAt
          customerBreakdownBreakdownsId
          customerBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerBreakdowns = /* GraphQL */ `
  query ListCustomerBreakdowns(
    $date: String
    $filter: ModelCustomerBreakdownFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerBreakdowns(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerBreakdownForStaff = /* GraphQL */ `
  query GetCustomerBreakdownForStaff($id: ID!) {
    getCustomerBreakdownForStaff(id: $id) {
      id
      customerBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      support
      communication
      positiveImpact
      overallPerformance
      valueForMoney
      createdAt
      updatedAt
      customerBreakdownBreakdownsId
      customerBreakdownForStaffStaffId
    }
  }
`;
export const listCustomerBreakdownForStaffs = /* GraphQL */ `
  query ListCustomerBreakdownForStaffs(
    $filter: ModelCustomerBreakdownForStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerBreakdownForStaffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerBreakdown {
          date
          createdAt
          updatedAt
        }
        staff {
          id
          name
          department
          createdAt
          updatedAt
        }
        support
        communication
        positiveImpact
        overallPerformance
        valueForMoney
        createdAt
        updatedAt
        customerBreakdownBreakdownsId
        customerBreakdownForStaffStaffId
      }
      nextToken
    }
  }
`;
export const getServiceDeliveryBreakdown = /* GraphQL */ `
  query GetServiceDeliveryBreakdown($date: String!) {
    getServiceDeliveryBreakdown(date: $date) {
      date
      breakdowns {
        items {
          id
          onsiteDays
          startOnTime
          enoughTime
          lifestyleChanges
          professionalism
          assessmentRating
          recommend
          createdAt
          updatedAt
          serviceDeliveryBreakdownBreakdownsId
          serviceDeliveryBreakdownForStaffStaffId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listServiceDeliveryBreakdowns = /* GraphQL */ `
  query ListServiceDeliveryBreakdowns(
    $date: String
    $filter: ModelServiceDeliveryBreakdownFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceDeliveryBreakdowns(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getServiceDeliveryBreakdownForStaff = /* GraphQL */ `
  query GetServiceDeliveryBreakdownForStaff($id: ID!) {
    getServiceDeliveryBreakdownForStaff(id: $id) {
      id
      serviceDeliveryBreakdown {
        date
        breakdowns {
          nextToken
        }
        createdAt
        updatedAt
      }
      staff {
        id
        name
        department
        createdAt
        updatedAt
      }
      onsiteDays
      startOnTime
      enoughTime
      lifestyleChanges
      professionalism
      assessmentRating
      recommend
      createdAt
      updatedAt
      serviceDeliveryBreakdownBreakdownsId
      serviceDeliveryBreakdownForStaffStaffId
    }
  }
`;
export const listServiceDeliveryBreakdownForStaffs = /* GraphQL */ `
  query ListServiceDeliveryBreakdownForStaffs(
    $filter: ModelServiceDeliveryBreakdownForStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceDeliveryBreakdownForStaffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceDeliveryBreakdown {
          date
          createdAt
          updatedAt
        }
        staff {
          id
          name
          department
          createdAt
          updatedAt
        }
        onsiteDays
        startOnTime
        enoughTime
        lifestyleChanges
        professionalism
        assessmentRating
        recommend
        createdAt
        updatedAt
        serviceDeliveryBreakdownBreakdownsId
        serviceDeliveryBreakdownForStaffStaffId
      }
      nextToken
    }
  }
`;
export const getIso = /* GraphQL */ `
  query GetIso($date: String!) {
    getIso(date: $date) {
      date
      clientComplaints
      improvementLogOpen
      auditsCompleted
      nonConformityOpen
      carsRaised
      supplierIssuesRaised
      createdAt
      updatedAt
    }
  }
`;
export const listIsos = /* GraphQL */ `
  query ListIsos(
    $date: String
    $filter: ModelIsoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIsos(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        clientComplaints
        improvementLogOpen
        auditsCompleted
        nonConformityOpen
        carsRaised
        supplierIssuesRaised
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
